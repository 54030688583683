import React from "react";
import { SelectInput } from "ra-ui-materialui";

const FORMATTERS = ["text", "euro", "number"].map((id) => ({
  id,
  name: id,
}));

const FormatterInput = (props) => (
  <SelectInput choices={FORMATTERS} {...props} />
);
export default FormatterInput;
