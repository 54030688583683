import * as React from 'react';

import { ClassesOverride, Title, TitlePropType } from 'ra-ui-materialui';
import { ErrorInfo, Fragment, HtmlHTMLAttributes } from 'react';

import { API_URL } from '../../config';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Button from '@material-ui/core/Button';
import ErrorIcon from '@material-ui/icons/Report';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import History from '@material-ui/icons/History';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'ra-core';

const useStyles = makeStyles(
    theme => ({
        container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            [theme.breakpoints.down('sm')]: {
                padding: '1em',
            },
            fontFamily: 'Roboto, sans-serif',
            opacity: 0.5,
        },
        title: {
            display: 'flex',
            alignItems: 'center',
        },
        icon: {
            width: '2em',
            height: '2em',
            marginRight: '0.5em',
        },
        panel: {
            marginTop: '1em',
        },
        panelDetails: {
            whiteSpace: 'pre-wrap',
        },
        toolbar: {
            marginTop: '2em',
        },
    }),
    { name: 'RaError' }
);

function goBack() {
    window.history.go(-1);
}

const Error = (props: ErrorProps): JSX.Element => {
    const {
        error,
        errorInfo,
        classes: classesOverride,
        className,
        title,
        ...rest
    } = props;
    const classes = useStyles(props);
    const translate = useTranslate();
    React.useEffect(() => {
      fetch(`${API_URL}/log-ui-error`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json"
        },
        body: JSON.stringify({
          message: error.toString(),
          stack: error.stack || errorInfo?.componentStack || JSON.stringify(error)
        })
      })
    }, [error, errorInfo])
    return (
        <Fragment>
            {title && <Title defaultTitle={title} />}
            <div className={classnames(classes.container, className)} {...rest}>
                <h1 className={classes.title} role="alert">
                    <ErrorIcon className={classes.icon} />
                    {translate('ra.page.error')}
                </h1>
                <div>{translate('ra.message.error')}</div>
                {process.env.NODE_ENV !== 'production' && (
                    <Accordion className={classes.panel}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            {translate('ra.message.details')}
                        </AccordionSummary>
                        <AccordionDetails className={classes.panelDetails}>
                            <div>
                                <h2>
                                    {translate(error.toString(), {
                                        _: error.toString(),
                                    })}
                                </h2>
                                {errorInfo && errorInfo.componentStack}
                            </div>
                        </AccordionDetails>
                    </Accordion>
                )}
                <div className={classes.toolbar}>
                    <Button
                        variant="contained"
                        startIcon={<History />}
                        onClick={goBack}
                    >
                        {translate('ra.action.back')}
                    </Button>
                </div>
            </div>
        </Fragment>
    );
};

Error.propTypes = {
    classes: PropTypes.object,
    className: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    errorInfo: PropTypes.object,
    title: TitlePropType,
};

export interface ErrorProps extends HtmlHTMLAttributes<HTMLDivElement> {
    classes?: ClassesOverride<typeof useStyles>;
    className?: string;
    error: any;
    errorInfo?: ErrorInfo;
    title?: string;
}
export default Error;