let appUrl = `//${document.location.host}/`;
let environment = "PRODUCTION";
if (appUrl.endsWith(":3000/")) {
  appUrl = "//ingest.local/";
  environment = "DEVELOPER";
}
export const ACCEPTED_FILE_TYPES = [
  ".pdf",
  ".doc",
  ".docx",
  ".xls",
  ".xlsx",
  ".ppt",
  ".pptx",
  ".gif",
  ".png",
  ".jpg",
  ".jpeg",
  ".bmp",
  ".txt",
];

export const APP_URL = appUrl;
export const API_URL = `${APP_URL}api`;
export const SIGNUP_URL = `${APP_URL}signup/`;
export const VERSION = "1.6.6";
export const ENVIRONMENT = environment;
