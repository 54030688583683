import { SimpleForm, required } from "react-admin";

import React from "react";
import SubCategoriesInput from "./inputs/SubCategoriesInput";
import TextInput from "../components/inputs/TextInput";
import useValidatableSave from "../components/forms/useValidatableSave";

const Form = ({ ...props }) => {
  const save = useValidatableSave({ ...props, redirect: undefined });
  return (
    <SimpleForm {...props} save={save}>
      <TextInput source="name" maxLength={45} validate={[required()]} />
      <SubCategoriesInput source="order_activity_sub_categories" fullWidth />
    </SimpleForm>
  );
};
export default Form;
