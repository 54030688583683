import { FileField, FileInput as RaFileInput } from "react-admin";

import React from "react";

const FileInput = (props) => (
  <RaFileInput {...props} multiple={false}>
    <FileField source="filepath" title="filename" />
  </RaFileInput>
);
export default FileInput;
