import BackIcon from "@material-ui/icons/ArrowBack";
import { Button } from "react-admin";
import { Link } from "react-router-dom";
import React from "react";

const BackButton = ({ to }) => {
  return (
    <Button label={"ra.action.back"} component={Link} to={to}>
      <BackIcon />
    </Button>
  );
};
export default BackButton;
