import React, { useState } from "react";
import { useForm, useFormState } from "react-final-form";

import UserInput from "../../components/inputs/UserInput";
import { get } from "lodash";
import moment from "moment";

const OwnerInput = (props) => {
  const form = useForm();
  const formState = useFormState();
  const [isAssigned, setIsAssigned] = useState(false);
  const handleChange = (id) => {
    const assigned = get(formState.values, "assigned", null);
    if (assigned === null && id > 0 && !isAssigned) {
      form.change("assigned", moment().format("YYYY-MM-DD"));
      setIsAssigned(true);
    }
  };
  return <UserInput {...props} onChange={handleChange} />;
};
export default OwnerInput;
