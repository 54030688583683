import AutocompleteInput from "./AutocompleteInput";
import React from "react";
import { ReferenceInput } from "react-admin";

const CustomerEmployeeInput = (props) => (
  <ReferenceInput
    {...props}
    reference="customer-employees"
    sort={{ field: "name", order: "ASC" }}
  >
    <AutocompleteInput optionText="fullname" />
  </ReferenceInput>
);
export default CustomerEmployeeInput;
