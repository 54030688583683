import { DateField, EditButton, useRedirect } from "react-admin";
import ListField, { makeRedirect } from "../../components/fields/ListField";

import IdField from "../../order-activities/fields/IdField";
import LongTextField from "../../components/fields/LongTextField";
import React from "react";
import ReminderField from "./ReminderField";
import StatusField from "./StatusField";
import { get } from "lodash";
import useFieldLabel from "../../components/useFieldLabel";

const RemindersField = ({
  view = "customers",
  isCustomer = false,
  ...props
}) => {
  const redirect = useRedirect();
  const fieldLabel = useFieldLabel({ resource: "reminders" });
  const id = get(props, "record.id", 0);
  if (id <= 0) {
    return null;
  }
  const additionalData =
    view === "customer-employees"
      ? {
          customer_id: get(props, "record.customer_id", null),
        }
      : {};
  return (
    <ListField
      {...props}
      reference="reminders"
      target={view === "customers" ? "customer_id" : "customer_employee_id"}
      canModify={!isCustomer}
      canCreate={!isCustomer}
      canRemove={!isCustomer}
      addLabel={false}
      additionalData={{
        source: view,
        ...additionalData,
      }}
      sort={{ field: "Reminders.reminder_date", order: "ASC" }}
      filter={{
        source: view,
      }}
      removeRedirect={() => redirect(makeRedirect({ ...props, tab: 1 }))}
    >
      <IdField label="ID" source="id" />
      <LongTextField
        source="reminder_category.name"
        sortBy="ReminderCategories.name"
        label={fieldLabel("reminder_category_id")}
      />
      <StatusField source="status" sortBy="status" />
      <DateField source="expiry_date" />
      <ReminderField source="reminder_date" sortBy="Reminders.reminder_date" />
      {isCustomer && <EditButton label="ra.action.view" />}
    </ListField>
  );
};

export default RemindersField;
