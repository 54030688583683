import { SelectInput, useQuery } from "react-admin";

import React from "react";

const MonthInput = ({ column, filterValues, ...props }) => {
  const { loading, data } = useQuery({
    type: "getList",
    resource: "reminder-months",
    payload: {
      pagination: { page: 1, perPage: 100 },
      sort: { field: "month", order: "ASC" },
      filter: { column, year: filterValues?.year },
    },
  });
  return loading ? null : (
    <SelectInput {...props} choices={data} optionText="month" />
  );
};
export default MonthInput;
