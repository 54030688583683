import { FormTab, TabbedForm, required } from "react-admin";

import OfficesInput from "./inputs/OfficesInput";
import React from "react";
import { ReminderIcon } from "../icons";
import RemindersField from "../reminders/fields/RemindersField";
import SettingsIcon from "@material-ui/icons/Settings";
import TextInput from "../components/inputs/TextInput";
import useValidatableSave from "../components/forms/useValidatableSave";

const Form = ({ errors, validate, dispatch, ...props }) => {
  const save = useValidatableSave({ ...props, redirect: undefined });
  return (
    <TabbedForm {...props} save={save}>
      <FormTab label="resources.customers.tabs.general" icon={<SettingsIcon />}>
        <TextInput
          source="name"
          validate={required()}
          maxLength={100}
          fullWidth
        />
        <TextInput source="vat_code" validate={required()} maxLength={16} />
        <TextInput source="sdi_code" maxLength={100} />
        <TextInput source="referent" maxLength={100} />
        <TextInput source="phone" type="tel" maxLength={45} />
        <TextInput source="email" type="email" maxLength={250} />
        <OfficesInput source="customer_offices" fullWidth />
      </FormTab>
      <FormTab
        label="resources.customers.tabs.reminders"
        icon={<ReminderIcon />}
      >
        <RemindersField source="reminders" fullWidth />
      </FormTab>
    </TabbedForm>
  );
};
export default Form;
