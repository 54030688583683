import CustomerEmployeeIcon from "@material-ui/icons/AccountCircle";
import CustomerIcon from "@material-ui/icons/SupervisedUserCircle";
import DashboardIcon from "@material-ui/icons/Dashboard";
import IndicatorIcon from "@material-ui/icons/Settings";
import LanguageMessagesIcon from "@material-ui/icons/Language";
import NotificationsIcon from "@material-ui/icons/Notifications";
import OrderActivityCategoryIcon from "@material-ui/icons/Category";
import OrderActivityIcon from "@material-ui/icons/Work";
import OrderActivityProgressIcon from "@material-ui/icons/FindReplace";
import OrderCategoryIcon from "@material-ui/icons/Assignment";
import OrderIcon from "@material-ui/icons/DoubleArrow";
import OrderIndicatorIcon from "@material-ui/icons/Equalizer";
import PagesIcon from "@material-ui/icons/Pages";
import RedirectsIcon from "@material-ui/icons/Cached";
import ReminderCategoryIcon from "@material-ui/icons/EventAvailable";
import ReminderIcon from "@material-ui/icons/AccessTime";
import RoleIcon from "@material-ui/icons/Lock";
import UserIcon from "@material-ui/icons/Face";
import UserPaymentIcon from "@material-ui/icons/AccountBalanceWallet";
import UserProfileApprovalIcon from "@material-ui/icons/AssignmentTurnedIn";
import GroupIcon from "@material-ui/icons/Toc";
import DocumentCategories from "@material-ui/icons/InsertDriveFile";
import DocumentIcon from "@material-ui/icons/Description";

export {
  CustomerIcon,
  CustomerEmployeeIcon,
  DashboardIcon,
  IndicatorIcon,
  LanguageMessagesIcon,
  NotificationsIcon,
  OrderActivityIcon,
  OrderActivityCategoryIcon,
  OrderCategoryIcon,
  OrderIcon,
  OrderIndicatorIcon,
  OrderActivityProgressIcon,
  PagesIcon,
  RedirectsIcon,
  RoleIcon,
  UserIcon,
  UserPaymentIcon,
  UserProfileApprovalIcon,
  ReminderCategoryIcon,
  ReminderIcon,
  GroupIcon,
  DocumentCategories,
  DocumentIcon,
};
