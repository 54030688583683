import { SimpleForm, required, useRedirect } from "react-admin";

import FileInput from "./inputs/FileInput";
import React from "react";
import ReferenceToolbar from "../components/forms/ReferenceToolbar";
import { makeRedirect } from "../components/fields/ListField";

const Form = (props) => {
  const { record } = props;
  const redirect = useRedirect();
  return (
    <SimpleForm
      {...props}
      initialValues={{ ...record }}
      toolbar={
        <ReferenceToolbar
          backRedirect="/order-activities"
          backReference="orders"
          backReferenceTarget="order_id"
          backTab={3}
        />
      }
      redirect={() => redirect(makeRedirect({ ...props, tab: 3 }))}
    >
      <FileInput source="doc" validate={required()} />
    </SimpleForm>
  );
};
export default Form;
