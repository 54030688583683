import {
  DeleteWithConfirmButton,
  Toolbar as RaToolbar,
  SaveButton,
} from "react-admin";
import React, { useMemo } from "react";

import BackButton from "../buttons/BackButton";
import { get } from "lodash";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    "& button, & a": {
      marginRight: theme.spacing(2),
    },
  },
}));

const ReferenceToolbar = ({
  backRedirect,
  backReferenceTarget,
  backReference,
  backTab = 1,
  canSave = true,
  canGoBack = true,
  ...props
}) => {
  const classes = useStyles();
  const { record } = props;
  const id = get(record, "id", 0);
  const referenceId = get(record, backReferenceTarget, 0);

  const backUrl = useMemo(
    () =>
      referenceId === 0
        ? backRedirect
        : backTab > 0
        ? `/${backReference}/${referenceId}/${backTab}`
        : `/${backReference}/${referenceId}`,
    [backRedirect, backReference, referenceId, backTab]
  );
  return (
    <RaToolbar {...props} className={classes.toolbar}>
      <SaveButton redirect={backUrl} disabled={!canSave} />
      {canGoBack && <BackButton to={backUrl} />}
      {id > 0 && <DeleteWithConfirmButton redirect={backUrl} />}
    </RaToolbar>
  );
};

export default ReferenceToolbar;
