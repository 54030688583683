import { useMutation, useNotify, useRedirect } from "react-admin";

import { get } from "lodash";
import { useCallback } from "react";

const mapFieldErrors = (field, errors) => {
  const keys = Object.keys(errors);
  const messages = keys.filter((k) => typeof errors[k] === "string");

  if (messages.length > 0) {
    return {
      [field]: messages.map((m) => errors[m]).join("\n"),
    };
  } else {
    const out = keys.reduce(
      (errorMap, key) => ({
        ...errorMap,
        ...mapFieldErrors(key, errors[key]),
      }),
      {}
    );
    return {
      [field]: out,
    };
  }
};

let cakephpErrorMapper = (errors) => {
  var fields = Object.keys(errors);
  var validationErrors = fields.reduce((errorsMap, field) => {
    return {
      ...errorsMap,
      ...mapFieldErrors(field, errors[field]),
    };
  }, {});
  return validationErrors;
};
const useValidatableSave = ({
  type = null,
  resource,
  onSuccess = undefined,
  ...props
}) => {
  const [mutate] = useMutation();
  const redirect = useRedirect();
  const notify = useNotify();
  const save = useCallback(
    async (values) => {
      try {
        await mutate(
          {
            type:
              type === null
                ? values && values.id && values.id > 0
                  ? "update"
                  : "create"
                : type,
            resource,
            payload: { id: get(values, "id"), data: values },
          },
          { returnPromise: true }
        );
      } catch (error) {
        const errors = get(error, "body.data.errors", null);
        const message = get(error, "body.data.message", null);
        if (message) {
          notify(message, "error");
        }

        if (errors) {
          const mappedErrors = cakephpErrorMapper(errors);
          return mappedErrors;
        } else {
          return false;
        }
      }
      if (props.redirect !== undefined) {
        redirect(
          typeof props.redirect === "function"
            ? props.redirect()
            : props.redirect
        );
      } else if (props.redirect !== false) {
        redirect("list", props.basePath);
      }
      if (!onSuccess) {
        notify(
          "ra.notification." + (values.id > 0 ? "updated" : "created"),
          "info",
          {
            smart_count: 1,
          }
        );
      } else onSuccess();
    },
    [mutate, type, resource, redirect, notify, onSuccess, props]
  );
  return save;
};

export default useValidatableSave;
