import React, { useState } from "react";
import {
  Notification,
  useNotify,
  useRedirect,
  useDataProvider,
  useTranslate,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import { TextField, Button } from "@material-ui/core";
import { Title } from "react-admin";
import Avatar from "@material-ui/core/Avatar";
import LockIcon from "@material-ui/icons/Lock";

import CircularProgress from "@material-ui/core/CircularProgress";

const styles = (theme) => ({
  root: {
    display: "flex",
    minHeight: "100vh",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "flex-start",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundColor: "GhostWhite",
  },
  form: {
    minWidth: 300,
    marginTop: "6em",
  },
  header: {
    margin: "1em",
    display: "flex",
    justifyContent: "center",
  },
  buttonsContainer: {
    flex: 1,
    flexDirection: "column",
    margin: theme.spacing(1),
    "& > :not(:first-child)": {
      marginLeft: 0,
    },
  },
  button: {
    display: "block",
    clear: "both",
    width: "100%",
    marginBottom: theme.spacing(1),
  },
  link: {
    width: "auto",
    alignSelf: "flex-start",
  },
  icon: {
    marginRight: theme.spacing(1),
  },
});

const useStyles = makeStyles(styles);
const PasswordResetPage = () => {
  const [state, setState] = useState({
    error: null,
    account: "",
    loading: false,
  });
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const redirect = useRedirect();
  const classes = useStyles();

  const submit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (state.account.length === 0) {
      setState({ ...state, error: translate("ra.validation.required") });
      return;
    }
    setState({ ...state, loading: true });

    dataProvider
      .create("users/reset", { data: { account: state.account } })
      .then((response) => {
        notify(translate("ra.auth.password_reset.success"));
        redirect("/login");
      })
      .catch(
        ({
          message,
          body: {
            data: { code },
          },
        }) => {
          if (code === 422) {
            setState({ ...state, loading: false, error: message });
          } else {
            notify(message, "warning");
          }
        }
      );
  };

  const change = (evt) => setState({ ...state, account: evt.target.value });

  return (
    <div className={classes.root}>
      <form className={classes.form} onSubmit={submit}>
        <Card>
          <Title title={translate("ra.auth.password_reset.title")} />
          <div className={classes.header}>
            <Avatar>
              <LockIcon />
            </Avatar>
          </div>
          <CardContent>
            <TextField
              autoFocus
              label={translate("ra.auth.password_reset.account")}
              fullWidth
              error={state.error !== null}
              helperText={state.error}
              onChange={change}
            />
          </CardContent>
          <CardActions className={classes.buttonsContainer}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              type="submit"
              disabled={state.loading}
            >
              {state.loading && (
                <CircularProgress
                  className={classes.icon}
                  size={18}
                  thickness={2}
                />
              )}
              {translate("ra.auth.password_reset.button")}
            </Button>

            <Button className={classes.link} href="/#/login" color="primary">
              &larr;
              {translate("ra.auth.back_to_login")}
            </Button>
          </CardActions>
        </Card>
      </form>
      <Notification />
    </div>
  );
};

export default PasswordResetPage;
