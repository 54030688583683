import { ReferenceInput, SelectInput } from "react-admin";

import React from "react";
import { get } from "lodash";
import { useFormState } from "react-final-form";

const CategoryInput = ({ isAdmin, ...props }) => {
  const formState = useFormState();
  const orderId = get(formState.values, "order_id", -1);
  return (
    <ReferenceInput
      {...props}
      reference="order-activity-categories"
      filter={{ order_id: isAdmin ? undefined : orderId }}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  );
};
export default CategoryInput;
