import { DateInput, Filter, SearchInput } from "react-admin";

import CategoryInput from "../order-activities/inputs/CategoryInput";
import IndicatorInput from "../indicators/inputs/IndicatorInput";
import OrderCategoryInput from "./inputs/OrderCategoryInput";
import React from "react";
import StatusInput from "./inputs/StatusInput";
import SubCategoryInput from "../order-activities/inputs/SubCategoryInput";
import UserInput from "../components/inputs/UserInput";
import { useFiltersStyles } from "../components/lists/useStyles";

// import GroupByInput from "./inputs/GroupByInput";

const Filters = ({ indicators, ...props }) => {
  const classes = useFiltersStyles();
  return (
    <Filter {...props} classes={classes}>
      {/* <GroupByInput source="group_by" alwaysOn fullWidth /> */}
      <SearchInput source="q" alwaysOn fullWidth />
      <OrderCategoryInput source="order_category_id" alwaysOn fullWidth />
      <StatusInput source="status" fullWidth />
      <OrderCategoryInput
        source="activity_order_category_id"
        fullWidth
        label={"resources.order-activities.fields.order_category_id"}
      />
      <CategoryInput
        source="order_activity_category_id"
        fullWidth
        isAdmin
        label={"resources.order-activities.fields.order_activity_category_id"}
      />
      <SubCategoryInput
        source="order_activity_sub_category_id"
        fullWidth
        isAdmin
        label={
          "resources.order-activities.fields.order_activity_sub_category_id"
        }
      />
      <DateInput source="start_at_gte" fullWidth />
      <DateInput source="start_at_lte" fullWidth />
      <DateInput source="end_at_gte" fullWidth />
      <DateInput source="end_at_lte" fullWidth />
      <DateInput source="assigned_gte" fullWidth />
      <DateInput source="assigned_lte" fullWidth />
      {indicators.map((indicator) => (
        <IndicatorInput
          key={indicator.id}
          label={indicator.name}
          source={indicator.code}
          indicator={indicator}
          fullWidth
        />
      ))}
      <UserInput source="owner_id" fullWidth />
    </Filter>
  );
};
export default Filters;
