import * as React from "react";

import { TextField, makeStyles } from "@material-ui/core";

import { get } from "lodash";
import { useTranslate } from "react-admin";

const styles = (theme) => ({
  formInput: {
    clear: "both",
    display: "block",
    width: "100%",
    marginBottom: theme.spacing(2),
  },
});
const useStyles = makeStyles(styles);
const TextInput = ({
  prefix = "",
  name,
  record,
  error,
  change,
  validate,
  type = "text",
  autoFocus = false,
  params = "",
}) => {
  const [valid, setValid] = React.useState(true);
  const translate = useTranslate();
  const classes = useStyles();
  const handleChange = (evt) => {
    let value = evt.target.value;
    let valid = validate ? validate(value) : true;
    setValid(valid);
    change(name, value, valid && valid !== true);
  };
  const hasErrors = (valid && valid !== true) || (error && error !== false);

  return (
    <TextField
      autoFocus={autoFocus}
      fullWidth
      type={type}
      className={classes.formInput}
      label={translate(`${prefix}.${name}${params}`)}
      error={hasErrors === true}
      value={get(record, name) || ""}
      helperText={
        valid && valid !== true
          ? translate(valid)
          : error !== null
          ? translate(error)
          : ""
      }
      onChange={handleChange}
    />
  );
};
export default TextInput;
