import List from "./List";
import { OrderActivityProgressIcon } from "../icons";

export default {
  options: {
    group: "dashboard",
    roles: ["user", "admin", "developer"],
  },
  icon: OrderActivityProgressIcon,
  list: List,
};
