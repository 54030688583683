import { ReferenceInput, SelectInput } from "react-admin";

import React from "react";
import { get } from "lodash";
import { useFormState } from "react-final-form";

const SubCategoryInput = ({ isAdmin, ...props }) => {
  const formState = useFormState();
  const categoryId = get(formState.values, "order_activity_category_id", -1);
  const orderId = get(formState.values, "order_id", -1);
  return (
    <ReferenceInput
      {...props}
      reference="order-activity-sub-categories"
      filter={{
        order_activity_category_id: categoryId,
        order_id: isAdmin ? undefined : orderId,
      }}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  );
};
export default SubCategoryInput;
