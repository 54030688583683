import Create from "./Create";
import { DocumentIcon } from "../icons";
import Edit from "./Edit";
import List from "./List";

export default {
  icon: DocumentIcon,
  options: {
    group: "customers",
    roles: ["developer", "planner", "customer", "admin"],
  },
  create: Create,
  list: List,
  edit: Edit,
};
