import React, { useEffect, useMemo } from "react";
import { useDataProvider, useTranslate } from "react-admin";

import { Alert } from "@material-ui/lab";
import { Collapse } from "@material-ui/core";
import useDebounce from "../../utils/useDebounce";
import { useFormState } from "react-final-form";

const InfoField = () => {
  const [{ count, loading }, setState] = React.useState({
    count: false,
    loading: false,
  });
  const formState = useFormState();
  const dataProvider = useDataProvider();
  const id = useMemo(() => formState.values.id, [formState]);
  const name = useMemo(() => formState.values.name, [formState.values.name]);
  const surname = useMemo(
    () => formState.values.surname,
    [formState.values.surname]
  );

  const debouncedName = useDebounce(name, 1000);
  const debouncedSurname = useDebounce(surname, 1000);
  const translate = useTranslate();
  useEffect(() => {
    if (debouncedName && debouncedSurname) {
      dataProvider
        .getList("customer-employees", {
          filter: {
            nid: id,
            name: debouncedName,
            surname: debouncedSurname,
          },
          sort: {
            field: "name",
            order: "ASC",
          },
          pagination: {
            page: 1,
            perPage: 1,
          },
        })
        .then(({ total }) => setState({ count: total, loading: false }))
        .catch((error) => console.error(error));
    }
  }, [dataProvider, id, debouncedName, debouncedSurname]);

  return (
    <Collapse in={!loading && count !== false}>
      <Alert severity="info">
        {translate("resources.customer-employees.info.message", {
          count,
        })}
      </Alert>
    </Collapse>
  );
};

export default InfoField;
