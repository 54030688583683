import React, { useEffect, useMemo } from "react";
import { ReferenceInput, SelectInput, useGetIdentity } from "react-admin";
import { useFormState, useForm } from "react-final-form";
import { get } from "lodash";

const CustomerOfficeInput = ({ isCustomer, ...props }) => {
  const formState = useFormState({ subscription: { values: true } });
  const form = useForm();
  const { identity } = useGetIdentity();
  const customerId = isCustomer
    ? identity?.customer_id
    : get(formState.values, "customer_id", 0);

  const showCustomerFilter = useMemo(() => {
    if (isCustomer) {
      return true;
    }
    if (customerId === 0) {
      return false;
    } else {
      return true;
    }
  }, [isCustomer, customerId]);

  useEffect(() => {
    form.change(props.source, []);
  }, [customerId, form, props.source]);

  if (showCustomerFilter) {
    return (
      <ReferenceInput
        {...props}
        reference="customer-offices"
        filter={{ customer_id: customerId }}
        sort={{ field: "name", order: "ASC" }}
        perPage={1000}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
    );
  } else {
    return null;
  }
};
export default CustomerOfficeInput;
