import { SelectInput, useQuery } from "react-admin";

import React from "react";

const YearInput = ({ column, ...props }) => {
  const { loading, data } = useQuery({
    type: "getList",
    resource: "reminder-years",
    payload: {
      pagination: { page: 1, perPage: 100 },
      sort: { field: "year", order: "ASC" },
      filter: { column },
    },
  });
  return loading ? null : (
    <SelectInput {...props} choices={data} optionText="year" />
  );
};
export default YearInput;
