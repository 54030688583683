import React from "react";
import { SimpleForm, required } from "react-admin";
import useValidatableSave from "../components/forms/useValidatableSave";
import TextInput from "../components/inputs/TextInput";

const Form = (props) => {
  const save = useValidatableSave({ ...props, redirect: undefined });
  return (
    <SimpleForm {...props} save={save}>
      <TextInput source="name" maxLength={45} validate={required()} />
    </SimpleForm>
  );
};

export default Form;
