import { get } from "lodash";
import { useQuery } from "ra-core";

const useNotificationCount = () => {
  const stats = useQuery({
    resource: "notifications/stats",
    type: "getOne",
    payload: {
      id: "me",
    },
  });
  const unreaded = get(stats, "data.unreaded", 0);
  const messages = get(stats, "data.notifications", 0);
  return { unreaded, messages };
};
export default useNotificationCount;
