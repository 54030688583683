import React, { Fragment } from "react";
import Form from "./Form";
import HelpProvider from "../components/HelpProvider";
import { Create as RaCreate } from "react-admin";
import { useFormStyles } from "../components/forms/useStyles";

const Create = (props) => {
  const classes = useFormStyles();
  return (
    <Fragment>
      <HelpProvider />
      <RaCreate {...props} classes={classes}>
        <Form />
      </RaCreate>
    </Fragment>
  );
};
export default Create;
