import React, { Fragment, useMemo } from "react";

import { Typography } from "@material-ui/core";
import { get } from "lodash";
import moment from "moment";
import { useTranslate } from "react-admin";

const ReminderField = ({ record, ...props }) => {
  const translate = useTranslate();
  const dateFormat = translate("app.date_format.short");
  const reminderDate = useMemo(() => {
    const reminderDate = get(record, "reminder_date");
    if (reminderDate !== null) {
      return moment(reminderDate).format(dateFormat);
    }
    return null;
  }, [record, dateFormat]);
  return (
    <Fragment>
      <Typography variant="body2">{reminderDate}</Typography>
      <Typography variant="caption">
        {translate(`resources.reminders.fields.reminder.formatted`, record)}
      </Typography>
    </Fragment>
  );
};
export default ReminderField;
