import React, { useEffect } from "react";

import AutocompleteInput from "./AutocompleteInput";
import { ReferenceInput } from "react-admin";
import { get } from "lodash";
import { useFormState } from "react-final-form";

const UserInput = ({ onChange = undefined, isAdmin = true, ...props }) => {
  const formState = useFormState();
  const value = get(formState.values, props.source);
  useEffect(() => {
    if (value && onChange) {
      onChange(value);
    }
  }, [value, onChange]);
  if (!isAdmin) {
    return null;
  }
  return (
    <ReferenceInput
      {...props}
      reference="users"
      sort={{ field: "username", order: "ASC" }}
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
  );
};
export default UserInput;
