import { Filter, SearchInput } from "ra-ui-materialui";

import React from "react";

const Filters = (props) => {
  return (
    <Filter {...props}>
      <SearchInput source="q" alwaysOn fullWidth />
    </Filter>
  );
};
export default Filters;
