import { InputAdornment } from "@material-ui/core";
import { NumberInput } from "react-admin";
import React from "react";

const MoneyInput = (props) => (
  <NumberInput
    {...props}
    InputProps={{
      startAdornment: <InputAdornment position="start">€</InputAdornment>,
    }}
  />
);
export default MoneyInput;
