import {
  BooleanField,
  DateField,
  EditButton,
  List as RaList,
  TextField,
} from "react-admin";

import Filters from "./Filters";
import OrderIndexField from "./fields/OrderIndexField";
import React from "react";
import ResponsiveList from "../components/lists/ResponsiveList";
import perPage from "../utils/per-page";
import { useListStyles } from "../components/lists/useStyles";

const List = (props) => {
  const classes = useListStyles();
  return (
    <RaList
      {...props}
      classes={classes}
      filters={<Filters />}
      exporter={false}
      perPage={perPage(25, 25)}
      sort={{ field: "order_index", order: "ASC" }}
    >
      <ResponsiveList
        mobileHeader={
          <React.Fragment>
            <TextField
              source="name"
              variant="h6"
              style={{ fontWeight: "bold" }}
            />
            <EditButton />
          </React.Fragment>
        }
        mobileBody={
          <React.Fragment>
            <TextField source="type" />
            <TextField source="code" />
            <TextField source="name" />
          </React.Fragment>
        }
      >
        <TextField source="id" variant="subtitle2" />
        <TextField source="name" variant="subtitle2" />
        <TextField source="code" variant="caption" />
        <OrderIndexField source="order_index" />
        <TextField source="formatter" />
        <BooleanField source="is_visible" />
        <DateField source="modified" showTime />
        <EditButton />
      </ResponsiveList>
    </RaList>
  );
};
export default List;
