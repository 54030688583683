import getHeaders from "./authHeaders";

const { API_URL } = require("./config");
export const useRoles = () =>
  JSON.parse(localStorage.getItem("roles") || "[]") || [];

const authProvider = {
  login: (params) => {
    const { username, password } = params;
    const requestURL = `${API_URL}/users/login`;
    const request = new Request(requestURL, {
      method: "POST",
      body: JSON.stringify({ username, password }),
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
    });
    return fetch(request)
      .then((response) => response.json())
      .then(({ data }) => {
        if (data.code === 401) {
          throw new Error(data.message);
        }
        localStorage.setItem("token", data.token);
        localStorage.setItem("roles", JSON.stringify(data.roles));
        localStorage.setItem("groups", JSON.stringify(data.groups));
        localStorage.setItem("profile", JSON.stringify(data.profile));
        localStorage.setItem("customer_id", data.customer_id);
      });
  },
  logout: () => {
    localStorage.removeItem("token");
    localStorage.removeItem("roles");
    localStorage.removeItem("groups");
    localStorage.removeItem("profile");
    localStorage.removeItem("customer_id");
    return Promise.resolve();
  },
  checkAuth: () =>
    localStorage.getItem("token") ? Promise.resolve() : Promise.reject(),
  checkError: (error) => {
    if (error.status === 401 || error.status === 500) {
      return Promise.reject();
    }
    return Promise.resolve();
  },
  getPermissions: () => {
    let roles = JSON.parse(localStorage.getItem("roles"));
    return Promise.resolve(
      (v) => roles && roles.some((r) => v.includes(r.code))
    );
  },

  getIdentity: () => {
    const profile = JSON.parse(localStorage.getItem("profile"));
    const groups = JSON.parse(localStorage.getItem("groups"));
    const customer_id = localStorage.getItem("customer_id");
    return Promise.resolve({ ...profile, groups, customer_id });
  },

  impersonate: (id) => {
    const requestURL = `${API_URL}/users/impersonate/?id=${id}`;
    const request = new Request(requestURL, {
      method: "POST",
      headers: getHeaders(),
    });
    return fetch(request)
      .then((response) => response.json())
      .then(({ success, data }) => {
        if (!success) {
          throw new Error(data.message);
        }
        [
          "token",
          "roles",
          "groups",
          "username",
          "profile",
          "customer_id",
        ].forEach((param) => {
          const toSaveParam = `admin_${param}`;
          localStorage.setItem(toSaveParam, localStorage.getItem(param));
          localStorage.setItem(
            param,
            ["profile", "roles", "groups", "customer_id"].indexOf(param) !== -1
              ? JSON.stringify(data[param])
              : data[param]
          );
        });
        localStorage.setItem("impersonate", true);
      });
  },
  stopImpersonate() {
    ["token", "roles", "groups", "username", "profile", "customer_id"].forEach(
      (param) => {
        const savedParam = `admin_${param}`;
        localStorage.setItem(param, localStorage.getItem(savedParam));
        localStorage.removeItem(savedParam);
      }
    );
    localStorage.setItem("impersonate", false);
    return Promise.resolve();
  },
};

export default authProvider;
