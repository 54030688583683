import { SimpleForm, required } from "react-admin";

import React from "react";
import TextInput from "../components/inputs/TextInput";
import useValidatableSave from "../components/forms/useValidatableSave";
import GroupsInput from "../components/inputs/GroupsInput";

const Form = (props) => {
  const save = useValidatableSave({ ...props, redirect: undefined });
  return (
    <SimpleForm {...props} save={save}>
      <TextInput source="code" maxLength={100} />
      <TextInput source="name" maxLength={250} validate={required()} />
      <GroupsInput source="groups" fullWidth />
    </SimpleForm>
  );
};
export default Form;
