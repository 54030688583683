import {
  DateField,
  EditButton,
  List as RaList,
  TextField,
  useTranslate,
} from "react-admin";
import React, { Fragment } from "react";
import { useListStyles, useSelector } from "../components/lists/useStyles";

import Filters from "./Filters";
import HelpProvider from "../components/HelpProvider";
import IdField from "./fields/IdField";
import LongTextField from "../components/fields/LongTextField";
import MoneyField from "../components/fields/MoneyField";
import PrintButton from "./buttons/PrintButton";
import ResponsiveList from "../components/lists/ResponsiveList";
import StatusField from "./fields/StatusField";
import exporter from "./exporter";
import perPage from "../utils/per-page";

const List = (props) => {
  const sidebarOpen = useSelector((state) => state.admin.ui.sidebarOpen);
  const classes = useListStyles({ sidebarOpen });
  const translate = useTranslate();
  return (
    <Fragment>
      <HelpProvider />
      <RaList
        {...props}
        classes={classes}
        perPage={perPage(25, 25)}
        exporter={(data) => exporter(data, translate)}
        filters={<Filters />}
        filterDefaultValues={{ status: "open" }}
        sort={{ field: "id", order: "DESC" }}
      >
        <ResponsiveList
          mobileHeader={
            <Fragment>
              <LongTextField source="customer.name" variant="subtitle2" />
            </Fragment>
          }
          mobileBody={
            <Fragment>
              <IdField source="id" />
              <StatusField source="status" />
              <TextField
                source="order_category.name"
                sortBy="OrderCategories.name"
              />
              <MoneyField source="amount" />
            </Fragment>
          }
          mobileActions={
            <Fragment>
              <EditButton />
              <PrintButton />
            </Fragment>
          }
        >
          <IdField source="id" sortBy="uid" />
          <LongTextField source="description" />
          <StatusField source="status" />
          <TextField
            source="order_category.name"
            sortBy="OrderCategories.name"
          />
          <LongTextField
            source="customer.name"
            sortBy="Customers.name"
            variant="subtitle2"
          />
          <MoneyField source="amount" />
          <DateField source="modified" showTime />
          <PrintButton />
          <EditButton />
        </ResponsiveList>
      </RaList>
    </Fragment>
  );
};
export default List;
