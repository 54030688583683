import { get } from "lodash";

export const formatMany = (many) => {
  let array = many ? many.map((p) => get(p, "id")).filter((id) => id) : [];
  return array;
};

export const parseMany = (many) => {
  let objects = many ? many.map((id) => ({ id })) : [];
  return objects;
};
