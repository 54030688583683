import {
  DateInput,
  FormTab,
  NumberInput,
  TabbedForm,
  minValue,
  required,
} from "react-admin";
import { OrderActivityIcon, OrderIndicatorIcon } from "../icons";

import ActivitiesField from "./fields/ActivitiesField";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CustomerInput from "../components/inputs/CustomerInput";
import CustomerOfficeInput from "../components/inputs/CustomerOfficeInput";
import EuroIcon from "@material-ui/icons/Euro";
import IndicatorsField from "./fields/IndicatorsField";
import ManagementCostInput from "./inputs/ManagementCostInput";
import MediaField from "./fields/MediaField";
import MoneyInput from "../components/inputs/MoneyInput";
import OrderCategoryInput from "../components/inputs/OrderCategoryInput";
import OwnerInput from "./inputs/OwnerInput";
import PaymentsField from "./fields/PaymentsField";
import React from "react";
import SettingsIcon from "@material-ui/icons/Settings";
import StatusInput from "./inputs/StatusInput";
import TextInput from "../components/inputs/TextInput";
import Toolbar from "./Toolbar";
import classnames from "classnames";
import get from "lodash/get";
import { makeStyles } from "@material-ui/core";
import moment from "moment";
import useValidatableSave from "../components/forms/useValidatableSave";

const useStyles = makeStyles((theme) => ({
  input: {
    float: "left",
    marginRight: theme.spacing(2),
  },
  clearLeft: {
    clear: "left",
  },
}));

const Form = ({ create, ...props }) => {
  const classes = useStyles();
  const save = useValidatableSave({
    ...props,
    redirect: create ? undefined : false,
  });
  const created = get(props, "record.id", 0) > 0;
  const handleOwnerIdChange = (ownerId, form, formState) => {
    const assigned = get(formState.values, "assigned", null);
    if (assigned === null && ownerId && ownerId > 0) {
      form.change("assigned", moment().format("YYYY-MM-DD"));
    }
  };
  return (
    <TabbedForm
      {...props}
      save={save}
      initialValues={{
        status: "open",
        start_at: moment().format("YYYY-MM-DD"),
      }}
      toolbar={<Toolbar />}
    >
      <FormTab label="resources.orders.tabs.general" icon={<SettingsIcon />}>
        <NumberInput
          source="year"
          className={classes.input}
          validate={[minValue(2000), required()]}
        />
        <NumberInput source="number" validate={required()} />
        <TextInput
          className={classnames(classes.input, classes.clearLeft)}
          source="offer_order"
          maxLength={20}
        />
        <TextInput source="purchase_order" maxLength={20} />
        <CustomerInput source="customer_id" fullWidth validate={required()} />
        <CustomerOfficeInput source="customer_office_id" fullWidth resettable />
        <TextInput source="description" maxLength={1000} multiline fullWidth />
        <TextInput source="notes" maxLength={4000} multiline fullWidth />
        <OrderCategoryInput source="order_category_id" validate={required()} />
        <StatusInput source="status" validate={required()} />
        <OwnerInput source="owner_id" onChange={handleOwnerIdChange} />
        <DateInput source="assigned" fullWidth />
        <DateInput source="start_at" validate={required()} />
        <DateInput source="end_at" />
        <MoneyInput source="amount" validate={required()} />
        <NumberInput
          source="planned_payments"
          disabled={created}
          helperText={"resources.orders.fields.planned_payments.help"}
        />
        <ManagementCostInput fullWidth />
        <MoneyInput source="daily_user_cost" validate={required()} />
      </FormTab>

      {created && (
        <FormTab
          label="resources.orders.tabs.activities"
          icon={<OrderActivityIcon />}
        >
          <ActivitiesField source="planned_activities" planned fullWidth />
          <ActivitiesField
            source="executed_activities"
            planned={false}
            fullWidth
          />
        </FormTab>
      )}
      {created && (
        <FormTab label="resources.orders.tabs.payments" icon={<EuroIcon />}>
          <MoneyInput source="amount" validate={required()} />
          <NumberInput source="planned_payments" disabled={created} />
          <IndicatorsField
            source="indicators"
            fullWidth
            indicatorsToDisplay={["remaining_amount"]}
          />
          <PaymentsField source="payments" fullWidth />
        </FormTab>
      )}
      {created && (
        <FormTab label="resources.orders.tabs.media" icon={<AttachFileIcon />}>
          <MediaField source="media" fullWidth />
        </FormTab>
      )}
      {created && (
        <FormTab
          label="resources.orders.tabs.indicators"
          icon={<OrderIndicatorIcon />}
        >
          <IndicatorsField source="indicators" fullWidth />
        </FormTab>
      )}
    </TabbedForm>
  );
};
export default Form;
