import { Link } from "@material-ui/core";
import React from "react";
import { get } from "lodash";

const stopPropagation = (e) => e.stopPropagation();
const PhoneField = ({ source, record }) => {
  return (
    <Link href={`tel:${get(record, source)}`} onClick={stopPropagation}>
      {get(record, source)}
    </Link>
  );
};
export default PhoneField;
