import { Badge, IconButton, useMediaQuery } from "@material-ui/core";

import NotificationPopup from "./NotificationPopup";
import { NotificationsIcon } from "../../icons";
import React from "react";
import useNotificationCount from "./hook/useNotificationCount";

const NotificationIcon = () => {
  const { unreaded, messages } = useNotificationCount();
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  if (isSmall) {
    return (
      <IconButton color="inherit" href="#/notifications">
        <Badge color="primary" badgeContent={unreaded}>
          <NotificationsIcon />
        </Badge>
      </IconButton>
    );
  }
  return <NotificationPopup unreaded={unreaded} messages={messages} />;
};

export default NotificationIcon;
