import {
  CheckboxGroupInput,
  ReferenceArrayInput,
  usePermissions,
} from "react-admin";
import { formatMany, parseMany } from "../../utils/parsing";

import React, { useMemo } from "react";
import { grey } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core";

const GroupsCheckboxes = ({ choices, ...props }) => {
  const { loading, permissions } = usePermissions();
  const isDeveloper = !loading && permissions && permissions(["developer"]);
  const finalChoices = isDeveloper
    ? choices
    : choices.filter((c) => c.code !== "developer");
  return (
    <CheckboxGroupInput {...props} choices={finalChoices} optionText="name" />
  );
};

const GroupsInput = withStyles((theme) => ({
  root: {
    "& legend": {
      fontSize: 22,
      marginBottom: theme.spacing(2),
    },
    "& .MuiFormGroup-row": {
      borderTop: 1,
      borderTopStyle: "solid",
      borderTopColor: grey[400],
    },
  },
}))(({ classes, ...props }) => {
  const { loaded, permissions } = usePermissions();
  const isCustomer = useMemo(
    () => loaded && permissions(["customer"]),
    [loaded, permissions]
  );
  if (!isCustomer) {
    return (
      <ReferenceArrayInput
        {...props}
        reference="groups"
        format={formatMany}
        parse={parseMany}
        fullWidth
        className={classes.root}
      >
        <GroupsCheckboxes optionText="name" />
      </ReferenceArrayInput>
    );
  }
  return null;
});

export default GroupsInput;
