import { DateInput, SimpleForm, required, usePermissions } from "react-admin";

import CustomerEmployeeInput from "../components/inputs/CustomerEmployeeInput";
import CustomerInput from "../components/inputs/CustomerInput";
import MediaInput from "./inputs/MediaInput";
import React from "react";
import ReferenceToolbar from "../components/forms/ReferenceToolbar";
import ReminderCategoryInput from "./inputs/ReminderCategoryInput";
import ReminderInput from "./inputs/ReminderInput";
import TextInput from "../components/inputs/TextInput";
import { get } from "lodash";
import moment from "moment";
import { useHistory } from "react-router-dom";
import useValidatableSave from "../components/forms/useValidatableSave";
import GroupsInput from "../components/inputs/GroupsInput";
import CustomerOfficeInput from "./inputs/CustomerOfficeInput";

const EmptyToolbar = () => <div style={{ height: 24 }} />;

const Form = (props) => {
  const history = useHistory();
  const { loaded, permissions } = usePermissions();
  const isCustomer = loaded && permissions(["customer"]);
  const save = useValidatableSave({
    ...props,
    redirect: () => history.goBack(),
  });

  const source = get(props, "record.source", "customers");
  return (
    <SimpleForm
      {...props}
      save={save}
      initialValues={{
        expiry_date: moment().add(6, "months").format("YYYY-MM-DD"),
        reminder: 15,
        source: "customers",
      }}
      toolbar={
        isCustomer ? (
          <EmptyToolbar />
        ) : (
          <ReferenceToolbar
            backRedirect={`/${source}`}
            backReference={source}
            backReferenceTarget={
              source === "customers" ? "customer_id" : "customer_employee_id"
            }
            backTab={1}
          />
        )
      }
    >
      <CustomerInput
        disabled={isCustomer}
        source="customer_id"
        validate={required()}
      />
      <CustomerOfficeInput
        isCustomer={isCustomer}
        disabled={isCustomer}
        source="customer_office_id"
      />
      {source === "customer-employees" && (
        <CustomerEmployeeInput
          disabled={isCustomer}
          source="customer_employee_id"
          validate={required()}
        />
      )}
      <ReminderCategoryInput
        disabled={isCustomer}
        source="reminder_category_id"
        validate={required()}
      />
      <DateInput
        disabled={isCustomer}
        source="last_check"
        validate={required()}
      />
      <DateInput
        disabled={isCustomer}
        source="expiry_date"
        validate={required()}
      />
      <ReminderInput
        disabled={isCustomer}
        source="reminder"
        validate={required()}
      />
      <TextInput
        disabled={isCustomer}
        source="notes"
        maxLength={1000}
        multiline
        fullWidth
      />
      <MediaInput disabled={isCustomer} source="media" fullWidth />
      <GroupsInput disabled={isCustomer} source="groups" fullWidth />
    </SimpleForm>
  );
};
export default Form;
