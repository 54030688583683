import {
  DateInput,
  FormTab,
  TabbedForm,
  required,
  usePermissions,
} from "react-admin";

import CustomerInput from "../components/inputs/CustomerInput";
import InfoField from "./fields/InfoField";
import React from "react";
import { ReminderIcon } from "../icons";
import RemindersField from "../reminders/fields/RemindersField";
import SettingsIcon from "@material-ui/icons/Settings";
import TextInput from "../components/inputs/TextInput";
import { useHistory } from "react-router-dom";
import useValidatableSave from "../components/forms/useValidatableSave";
import GroupsInput from "../components/inputs/GroupsInput";

const EmptyToolbar = () => <div style={{ height: 24 }} />;

const Form = ({ ...props }) => {
  const history = useHistory();
  const { loaded, permissions } = usePermissions();
  const isCustomer = loaded && permissions(["customer"]);
  const save = useValidatableSave({
    ...props,
    redirect: () => history.goBack(),
  });
  return (
    <TabbedForm
      {...props}
      save={save}
      toolbar={isCustomer ? <EmptyToolbar /> : undefined}
    >
      <FormTab
        label="resources.customer-employees.tabs.general"
        icon={<SettingsIcon />}
      >
        <CustomerInput
          disabled={isCustomer}
          source="customer_id"
          fullWidth
          validate={required()}
        />
        <TextInput
          disabled={isCustomer}
          source="name"
          validate={required()}
          maxLength={100}
          fullWidth
        />
        <TextInput
          disabled={isCustomer}
          source="surname"
          validate={required()}
          maxLength={100}
          fullWidth
        />
        <InfoField disabled={isCustomer} source="info" fullWidth />
        <TextInput
          disabled={isCustomer}
          source="fiscal_code"
          validate={required()}
          maxLength={16}
        />
        <DateInput disabled={isCustomer} source="birth_date" />
        <TextInput disabled={isCustomer} source="birth_place" maxLength={100} />
        <TextInput
          disabled={isCustomer}
          source="job_title"
          maxLength={100}
          fullWidth
        />
        <TextInput
          disabled={isCustomer}
          source="phone"
          type="tel"
          maxLength={45}
        />
        <TextInput
          disabled={isCustomer}
          source="email"
          type="email"
          maxLength={250}
        />
        <GroupsInput disabled={isCustomer} source="groups" />
      </FormTab>
      <FormTab
        label="resources.customer-employees.tabs.reminders"
        icon={<ReminderIcon />}
      >
        <RemindersField
          view="customer-employees"
          source="reminders"
          fullWidth
          isCustomer={isCustomer}
        />
      </FormTab>
    </TabbedForm>
  );
};
export default Form;
