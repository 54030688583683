import React, { useEffect, useMemo } from "react";

import CustomerInput from "../../components/inputs/CustomerInput";
import { required } from "react-admin";
import { useFormState, useForm } from "react-final-form";

const Input = ({ ...props }) => {
  const formState = useFormState();
  const form = useForm();

  const isCustomer = useMemo(() => {
    if (formState.values?.roles) {
      return formState.values.roles.some((r) => r.id === 6);
    }
    return false;
  }, [formState.values.roles]);

  useEffect(() => {
    form.change(props.source, []);
  }, [form, props.source, isCustomer]);

  if (!isCustomer) {
    return null;
  }
  return <CustomerInput {...props} validate={required()} />;
};
export default Input;
