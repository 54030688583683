import { BooleanInput, NumberInput, SimpleForm, required } from "react-admin";

import CodeInput from "../components/inputs/CodeInput";
import FormatterInput from "./inputs/FormatterInput";
import GroupsInput from "../components/inputs/GroupsInput";
import React from "react";
import RolesInput from "../components/inputs/RolesInput";
import TextInput from "../components/inputs/TextInput";
import useValidatableSave from "../components/forms/useValidatableSave";

const Form = (props) => {
  const save = useValidatableSave({ ...props, redirect: undefined });
  return (
    <SimpleForm {...props} redirect="list" save={save}>
      <TextInput source="icon" validate={required()} />
      <TextInput source="code" validate={required()} fullWidth />
      <TextInput source="name" validate={required()} fullWidth />
      <TextInput source="description" maxLength={1000} fullWidth multiline />
      <FormatterInput source="formatter" validate={required()} />
      <NumberInput source="order_index" validate={required()} />
      <BooleanInput source="is_visible" />
      <CodeInput
        source="sql_expr"
        type="sql"
        fullWidth
        defaultValue=""
        validate={required()}
      />
      <RolesInput source="roles" fullWidth />
      <GroupsInput source="groups" fullWidth />
    </SimpleForm>
  );
};
export default Form;
