import { Chip, makeStyles } from "@material-ui/core";
import React from "react";
import get from "lodash/get";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0.5),
  },
}));

const GroupsField = ({ source, record }) => {
  const classes = useStyles();
  const groups = get(record, source);
  return groups.map((group, index) => (
    <Chip className={classes.root} key={index} label={group.name} />
  ));
};

export default GroupsField;
