import { Button, Link } from "@material-ui/core";

import { API_URL } from "../../config";
import PrintIcon from "@material-ui/icons/Print";
import React from "react";
import { get } from "lodash";
import { getToken } from "../../authHeaders";
import { useTranslate } from "react-admin";

const PrintButton = ({ record }) => {
  const recordId = get(record, "id", null);
  const url = `${API_URL}/orders/print?id=${recordId}&token=${getToken()}`;
  const translate = useTranslate();
  if (recordId === null) {
    return null;
  }
  return (
    <Button
      color="primary"
      startIcon={<PrintIcon />}
      component={Link}
      href={url}
      target="_blank"
    >
      {translate("resources.orders.buttons.print")}
    </Button>
  );
};
export default PrintButton;
