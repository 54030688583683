import {
  DateField,
  EditButton,
  EmailField,
  List as RaList,
  TextField,
  useTranslate,
} from "react-admin";
import React, { Fragment } from "react";
import { useListStyles, useSelector } from "../components/lists/useStyles";

import Filters from "./Filters";
import LongTextField from "../components/fields/LongTextField";
import PhoneField from "./fields/PhoneField";
import ResponsiveList from "../components/lists/ResponsiveList";
import exporter from "./exporter";
import perPage from "../utils/per-page";

const List = (props) => {
  const translate = useTranslate();
  const sidebarOpen = useSelector((state) => state.admin.ui.sidebarOpen);
  const classes = useListStyles({ sidebarOpen });
  return (
    <RaList
      {...props}
      classes={classes}
      perPage={perPage(25, 25)}
      filters={<Filters />}
      exporter={(data) => exporter(data, translate)}
      sort={{ field: "created", order: "DESC" }}
    >
      <ResponsiveList
        mobileHeader={
          <Fragment>
            <LongTextField source="name" variant="subtitle2" />
          </Fragment>
        }
        mobileBody={
          <Fragment>
            <EmailField source="email" />
            <PhoneField source="phone" />
          </Fragment>
        }
        mobileActions={
          <Fragment>
            <EditButton />
          </Fragment>
        }
      >
        <TextField source="id" />
        <LongTextField source="name" variant="subtitle2" />
        <EmailField source="email" />
        <PhoneField source="phone" />
        <DateField source="modified" showTime />
        <EditButton />
      </ResponsiveList>
    </RaList>
  );
};
export default List;
