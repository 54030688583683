import React from "react";
import ListItem from "@material-ui/core/ListItem";
import { ListItemText, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    padding: 0,
  },
});

const MediaField = ({ record }) => {
  const classes = useStyles();
  const recordMedias = record?.media;

  return recordMedias?.map((recordMedia, index) => (
    <ListItem
      className={classes.root}
      dense
      button
      component="a"
      key={index}
      href={recordMedia.file.path}
    >
      <ListItemText key={index} primary={recordMedia.file.name} />
    </ListItem>
  ));
};

export default MediaField;
