import { DateInput, Filter, SearchInput } from "react-admin";

import OrderCategoryInput from "../components/inputs/OrderCategoryInput";
import React from "react";
import StatusInput from "./inputs/StatusInput";
import UserInput from "../components/inputs/UserInput";
import { useFiltersStyles } from "../components/lists/useStyles";

const Filters = (props) => {
  const classes = useFiltersStyles();
  return (
    <Filter {...props} classes={classes}>
      <SearchInput source="q" alwaysOn fullWidth />
      <StatusInput source="status" alwaysOn fullWidth />
      <OrderCategoryInput source="order_category_id" fullWidth />
      <UserInput source="owner_id" fullWidth />
      <DateInput source="start_at_gte" fullWidth />
      <DateInput source="start_at_lte" fullWidth />
      <DateInput source="end_at_gte" fullWidth />
      <DateInput source="end_at_lte" fullWidth />
      <DateInput source="assigned_gte" fullWidth />
      <DateInput source="assigned_lte" fullWidth />
    </Filter>
  );
};
export default Filters;
