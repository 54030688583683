import { Chip, Menu, MenuItem } from "@material-ui/core";
import React, { useState } from "react";
import {
  useDataProvider,
  useNotify,
  useRefresh,
  useTranslate,
} from "react-admin";

import { get } from "lodash";
import statuses from "../statuses";

const StatusField = ({ source, record }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const status = get(record, source);
  const refresh = useRefresh();

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const handleChange = (status) => {
    setAnchorEl(null);
    dataProvider
      .update("users/change-status", {
        data: {
          id: record.id,
          status: status,
        },
      })
      .then(({ success, data }) => {
        refresh();
        notify(translate("resources.users.messages.status_changed"));
      })
      .catch(({ error: { message } }) => notify(message, "warning"));
  };

  return (
    <>
      <Chip
        onClick={handleClick}
        color="primary"
        label={translate(`resources.users.status.${status}`)}
      />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {statuses.map((statusItem) => (
          <MenuItem
            onClick={handleChange.bind(this, statusItem.id)}
            key={statusItem.id}
          >
            {translate(`resources.users.status.${statusItem.id}`)}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
export default StatusField;
