import {
  List as RaList,
  TextField,
  useQueryWithStore,
  useTranslate,
} from "react-admin";

import Filters from "./Filters";
import IndicatorField from "./fields/IndicatorField";
import LongTextField from "../components/fields/LongTextField";
import MoneyField from "../components/fields/MoneyField";
import OrderField from "./fields/OrderField";
import React from "react";
import ResponsiveList from "../components/lists/ResponsiveList";
import exporter from "./exporter";
import { get } from "lodash";
import perPage from "../utils/per-page";
import useFieldLabel from "../components/useFieldLabel";
import { useListStyles } from "../components/lists/useStyles";

const GROUP_BY_COLUMNS = {
  order: [],
  order_and_order_category: ["order_category"],
  order_and_order_category_and_order_activity_category: [
    "order_category",
    "order_activity_category",
  ],
  order_and_order_category_and_order_activity_category_and_order_activity_sub_category: [
    "order_category",
    "order_activity_category",
    "order_activity_sub_category",
  ],
};

const ListContent = ({ indicators, ...props }) => {
  const fieldLabel = useFieldLabel(props);
  const groupBy = get(props, "filterValues.group_by", "order");
  return (
    <ResponsiveList>
      <OrderField source="id" sortBy="uid" />
      <LongTextField source="customer_name" maxWidth={300} />
      <LongTextField source="description" maxWidth={300} />
      <MoneyField source="amount" />
      {GROUP_BY_COLUMNS[groupBy].map((columnName) => (
        <TextField
          key={columnName}
          source={columnName}
          label={fieldLabel(`${columnName}`)}
        />
      ))}
      {(indicators || []).map((indicator) => (
        <IndicatorField
          key={indicator.id}
          source={indicator.code}
          label={indicator.name}
          indicator={indicator}
          title={indicator.description}
        />
      ))}
    </ResponsiveList>
  );
};

const List = (props) => {
  const classes = useListStyles({ nowrap: true });
  const translate = useTranslate();
  const { data: indicators } = useQueryWithStore({
    type: "getList",
    resource: "indicators",
    payload: {
      sort: { field: "order_index", order: "ASC" },
      pagination: {
        page: 1,
        perPage: 100,
      },
      filter: {
        user: true,
      },
    },
  });

  return (
    <RaList
      {...props}
      classes={classes}
      bulkActionButtons={false}
      exporter={(data) => exporter(data, indicators, translate)}
      sort={{ field: "uid", order: "DESC" }}
      filters={<Filters indicators={indicators || []} />}
      filterDefaultValues={{ group_by: "order" }}
      perPage={perPage(25, 25)}
    >
      <ListContent indicators={indicators} />
    </RaList>
  );
};
export default List;
