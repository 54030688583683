import { ListItem, Typography } from "@material-ui/core";
import { useTranslate, useUpdateMany } from "react-admin";

import React from "react";
import classNames from "classnames";
import { get } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  listItem: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: theme.spacing(3),
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.primary[50],
    },
  },
  listItemUnreaded: {
    borderLeftStyle: "solid",
    borderLeftWidth: theme.spacing(0.5),
    borderLeft: theme.palette.primary[200],
    paddingLeft: theme.spacing(2.5),
  },
}));
const NotificationMessage = ({ message, onClick }) => {
  const translate = useTranslate();
  const classes = useStyles();
  const [updateMany] = useUpdateMany("notifications", [get(message, "id")], {
    readed: moment().format(),
  });
  const shouldUpdate = () => get(message, "readed") === null;
  const openLink = () =>
    (document.location.href = `#/${
      get(message, "resource") ?? "notifications"
    }`);
  const handleClick = () =>
    !onClick() && (!shouldUpdate() || !updateMany()) && !openLink();
  return (
    <ListItem
      alignItems="flex-start"
      onClick={handleClick}
      href={`#/${get(message, "resource")}`}
      className={classNames(
        classes.listItem,
        get(message, "readed") === null && classes.listItemUnreaded
      )}
    >
      <Typography gutterBottom>{get(message, "title")}</Typography>
      <Typography gutterBottom variant="body2">
        <span
          id="notification-message"
          dangerouslySetInnerHTML={{
            __html: get(message, "content"),
          }}
        />
      </Typography>

      <Typography variant="caption" color="textSecondary">
        {moment(get(message, "created")).format(
          translate("app.date_format.long")
        )}
      </Typography>
    </ListItem>
  );
};

export default NotificationMessage;
