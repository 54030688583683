import EmailIcon from "@material-ui/icons/Email";
import moment from "moment";
import React from "react";
import {
  Button,
  useNotify,
  useRefresh,
  useUnselectAll,
  useUpdateMany,
} from "react-admin";

const ReadButton = ({ selectedIds }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const [updateMany, { loading }] = useUpdateMany(
    "notifications",
    selectedIds,
    {
      readed: moment().format(),
    },
    {
      onSuccess: () => {
        refresh();
        notify("resources.notifications.actions.readed.done");
        unselectAll("notifications");
      },
      onFailure: (error) =>
        notify("resources.notifications.actions.readed.error", "warning"),
    }
  );

  return (
    <Button
      label="resources.notifications.actions.sign_as_readed"
      disabled={loading}
      onClick={updateMany}
    >
      <EmailIcon />
    </Button>
  );
};

export default ReadButton;
