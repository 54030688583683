import React, { Fragment } from "react";

import Form from "./Form";
import HelpProvider from "../components/HelpProvider";
import { Edit as RaEdit } from "react-admin";
import Title from "../components/Title";
import { useFormStyles } from "../components/forms/useStyles";

const Edit = (props) => {
  const classes = useFormStyles();
  return (
    <Fragment>
      <HelpProvider />
      <RaEdit
        {...props}
        classes={classes}
        title={<Title source="name" />}
        mutationMode="pessimistic"
      >
        <Form />
      </RaEdit>
    </Fragment>
  );
};
export default Edit;
