import OrderCategoryInput from "../../components/inputs/OrderCategoryInput";
import React from "react";
import { SelectInput } from "ra-ui-materialui";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  expr: {
    marginRight: -theme.spacing(1),
    width: "auto",
    minWidth: 80,
    maxWidth: 80,
    display: "inline-block",
  },
  input: {
    display: "inline-block",
  },
}));

const IndicatorInput = ({ ...props }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <SelectInput
        className={classes.expr}
        label="Expr"
        defaultValue="="
        source={`${props.source}_expr`}
        choices={["=", "<>"].map((id) => ({
          id,
          name: id,
        }))}
      />
      <OrderCategoryInput {...props} className={classes.input} />
    </div>
  );
};

export default IndicatorInput;
