import React from "react";
import { Typography } from "@material-ui/core";
import get from "lodash/get";

const IdField = ({ source, record, basePath, resource, sortBy, ...props }) => {
  return (
    <Typography variant="subtitle2" {...props}>
      {get(record, "number")}/{get(record, "year")}
    </Typography>
  );
};
export default IdField;
