import Create from "./Create";
import { GroupIcon } from "../icons";
import List from "./List";
import Edit from "./Edit";

export default {
  icon: GroupIcon,
  options: {
    group: "admin",
    roles: ["developer", "admin"],
  },
  list: List,
  create: Create,
  edit: Edit,
};
