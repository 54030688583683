import { Chip } from "@material-ui/core";
import React from "react";
import get from "lodash/get";
import { useTranslate } from "react-admin";

const StatusField = ({ source, record }) => {
  const translate = useTranslate();
  const status = get(record, source);
  return (
    <Chip
      label={translate(`resources.orders.status.${status}`)}
      color={status !== "closed" ? "primary" : "default"}
    />
  );
};
export default StatusField;
