import { useEffect, useState } from "react";
import { API_URL } from "./config";

const useNetwork = () => {
	const [data, setData] = useState({ loading: true, network: null });
	const getNetwork = () => {
		let headers = new Headers();
		headers.append("Accept", "application/json");
		headers.append("Content-Type", "application/json");
		fetch(`${API_URL}/network/info`, { headers })
			.then((response) => response.json())
			.then(({ data }) => setData({ loading: false, network: data }));
	};
	useEffect(() => {
		getNetwork();
	}, []);

	return data;
};

export default useNetwork;
