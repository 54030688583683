import { Link, Typography } from "@material-ui/core";

import React from "react";
import { get } from "lodash";

const OrderField = ({
  record,
  linkable = false,
  basePath,
  resource,
  sortBy,
  ...props
}) => {
  const year = get(record, "order.year");
  const number = get(record, "order.number");
  const orderId = get(record, "order_id");
  const Component = linkable && orderId ? Link : undefined;
  const additionalProps = linkable
    ? {
        href: `#/orders/${orderId}`,
      }
    : undefined;
  return (
    <Typography {...props} {...additionalProps} component={Component}>
      {number}/{year}
    </Typography>
  );
};
export default OrderField;
