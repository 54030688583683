import { DeleteWithConfirmButton as RaDeleteWithConfirmButton } from "react-admin";
import React from "react";

const DeleteWithConfirmButton = ({ redirect, reference, ...props }) => {
  const { record } = props;
  if (!record) {
    // Il tasto originale di react-admin soffre di un bug sul refresh.
    // Tenta il recupero di un id da un record che ormai non esiste più generando un blocco dell'applicazione.
    // Per ovviare al problema è necessario restituire null in questi casi.
    return null;
  }
  return <RaDeleteWithConfirmButton {...props} redirect={redirect} />;
};
export default DeleteWithConfirmButton;
