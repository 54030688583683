import { downloadCSV } from "react-admin";
import { get } from "lodash";
import jsonExport from "jsonexport/dist";
import moment from "moment";

const exporter = (data, translate) => {
  const columnName = (name) =>
    translate(`resources.order-activities.fields.${name}`);
  const dateFormatShort = translate("app.date_format.short");

  const formatNumber = (n) => (n !== null ? n.toString().replace(".", ",") : n);
  const dataToExport = data
    .filter((orderActivity) => orderActivity && orderActivity.id > 0)
    .map((orderActivity) => ({
      [columnName("id")]: get(orderActivity, "id"),
      [columnName("is_planned")]: get(orderActivity, "is_planned") ? "S" : "N",
      [columnName("order_id")]: `="${get(orderActivity, "order.number")}/${get(
        orderActivity,
        "order.year"
      )}"`,
      [columnName("customer")]: get(orderActivity, "order.customer.name"),

      [columnName("order_activity_category_id")]: get(
        orderActivity,
        "order_activity_category.name"
      ),
      [columnName("order_activity_sub_category_id")]: get(
        orderActivity,
        "order_activity_sub_category.name"
      ),
      [columnName("notes")]: get(orderActivity, "notes"),
      [columnName("total_cost")]: formatNumber(
        get(orderActivity, "total_cost")
      ),
      [columnName("total_days")]: formatNumber(
        get(orderActivity, "total_days")
      ),
      [columnName("date")]:
        get(orderActivity, "date") !== null
          ? moment(get(orderActivity, "date")).format(dateFormatShort)
          : "",
      [columnName("user_id")]: get(orderActivity, "user.nice_name"),
    }));
  jsonExport(
    dataToExport,
    {
      rowDelimiter: ";",
      headers: [
        columnName("id"),
        columnName("is_planned"),
        columnName("order_id"),
        columnName("customer"),
        columnName("order_activity_category_id"),
        columnName("order_activity_sub_category_id"),
        columnName("total_cost"),
        columnName("total_days"),
        columnName("date"),
        columnName("user_id"),
      ],
    },
    (err, csv) => downloadCSV("\uFEFF" + csv, "order-activities")
  );
};

export default exporter;
