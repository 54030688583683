import * as icons from "@material-ui/icons";

import {
  Card,
  Divider,
  ListItem,
  ListItemText,
  List as MuiList,
  Typography,
} from "@material-ui/core";

import CardIcon from "./CardIcon";
import React from "react";
import compose from "recompose/compose";
import { translate } from "react-admin";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  main: {
    flex: "1",
    marginTop: 40,
    [theme.breakpoints.up("sm")]: {
      marginRight: "1em",
    },
  },
  titleLink: { textDecoration: "none", color: "inherit" },
  card: {
    padding: "16px 0",
    overflow: "inherit",
    textAlign: "right",
  },
  title: {
    padding: "0 16px",
    paddingBottom: theme.spacing(1),
  },
  value: {
    padding: "0 16px",
    minHeight: 48,
  },
  listItem: {
    paddingBottom: 0,
  },
  listItemText: {
    overflowY: "hidden",
    height: "1.5em",
    fontFamily: "Fira Code, Consolas, Courier New",
  },
});

const List = ({ code, icon, rows = [], translate, classes, color, weight }) =>
  rows === false ? null : (
    <div className={classes.main}>
      <CardIcon Icon={icons[icon]} color={color} weight={weight} />
      <Card className={classes.card}>
        <Typography className={classes.title} color="textSecondary">
          {translate(`pages.dashboard.${code}.title`)}
        </Typography>
        <Divider />
        <MuiList dense>
          {rows.map((message, i) => (
            <ListItem className={classes.listItem} key={`log-${i}`}>
              <ListItemText
                primary={message}
                className={classes.listItemText}
                style={{ paddingRight: 0 }}
              />
            </ListItem>
          ))}
        </MuiList>
      </Card>
    </div>
  );

const enhance = compose(withStyles(styles), translate);

export default enhance(List);
