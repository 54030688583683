import { Button } from "react-admin";
import ContentDownload from "@material-ui/icons/GetApp";
import { Link } from "@material-ui/core";
import React from "react";
import { get } from "lodash";

const DownloadButton = ({ record, source }) => {
  const url = get(record, `${source}.path`, null);
  if (!url) {
    return null;
  }
  return (
    <Button color="primary" label={"app.download"} component={Link} href={url}>
      <ContentDownload />
    </Button>
  );
};
export default DownloadButton;
