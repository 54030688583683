import { Chip, IconButton } from "@material-ui/core";
import React, { Fragment } from "react";
import { useMutation, useRefresh } from "ra-core";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { get } from "lodash";

const MoveButton = ({ record, direction = "up", icon }) => {
  const refresh = useRefresh();
  const [move, { loading }] = useMutation(
    {
      type: "post",
      resource: "indicators/move-updown",
      payload: {
        body: {
          id: record.id,
          move: direction,
        },
      },
    },
    {
      onSuccess: () => refresh(),
    }
  );
  const handleMove = (e) => {
    e.stopPropagation();
    e.preventDefault();
    move();
  };
  return (
    <IconButton disabled={loading} onClick={handleMove}>
      {React.cloneElement(icon)}
    </IconButton>
  );
};

const OrderIndexField = ({ record, source }) => {
  return (
    <Fragment>
      <Chip label={get(record, source)} color="primary" />
      <MoveButton
        record={record}
        direction="down"
        icon={<ArrowDropDownIcon />}
      />
      <MoveButton record={record} direction="up" icon={<ArrowDropUpIcon />} />
    </Fragment>
  );
};
export default OrderIndexField;
