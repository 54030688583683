import "moment/locale/it";

import * as React from "react";

import { Admin, Resource, setAutomaticRefresh } from "react-admin";

import Dashboard from "./dashboard";
import Layout from "./components/layout/Layout";
import LoginPage from "./users/pages/LoginPage";
import Menu from "./components/layout/Menu";
import PasswordChangePage from "./users/pages/PasswordChangePage";
import PasswordResetPage from "./users/pages/PasswordResetPage";
import { Route } from "react-router-dom";
import authProvider from "./authProvider";
import customerEmployees from "./customer-employees";
import customers from "./customers";
import dataProvider from "./dataProvider";
import helpReducer from "./components/helpReducer";
import i18nProvider from "./i18nProvider";
import indicators from "./indicators";
import languageMessages from "./language-messages";
import notifications from "./notifications";
import orderActivities from "./order-activities";
import orderActivityCategories from "./order-activity-categories";
import orderActivityProgress from "./order-activity-progress";
import orderCategories from "./order-categories";
import orderIndicators from "./order-indicators";
import orderMedia from "./order-media";
import orderPayments from "./order-payments";
import orders from "./orders";
import reminderCategories from "./reminder-categories";
import reminders from "./reminders";
import theme from "./theme";
import useI18nCatcher from "./useI18nCatcher";
import useLanguages from "./useLanguages";
import userProfile from "./user-profile";
import users from "./users";
import groups from "./groups";
import documentCategories from "./document-categories";
import documents from "./documents";

const App = () => {
  useI18nCatcher();
  React.useEffect(() => {
    setAutomaticRefresh(false);
  }, []);
  const { loading, languages } = useLanguages();
  if (loading) {
    return null;
  }

  return (
    <Admin
      title="ingest.dev"
      menu={Menu}
      theme={theme}
      dashboard={Dashboard}
      dataProvider={dataProvider}
      authProvider={authProvider}
      i18nProvider={i18nProvider(languages)}
      loginPage={LoginPage}
      layout={Layout}
      customReducers={{
        help: helpReducer,
      }}
      customRoutes={[
        <Route
          path="/password-reset"
          component={PasswordResetPage}
          noLayout
          options={{ menu: false }}
        />,
        <Route path="/password-change" component={PasswordChangePage} />,
        <Route
          key="profile"
          path="/user-profile"
          component={userProfile.edit}
        />,
      ]}
    >
      <Resource name="notifications" {...notifications} />
      <Resource name="orders" {...orders} />
      <Resource name="order-payments" {...orderPayments} />
      <Resource name="order-indicators" {...orderIndicators} />
      <Resource name="order-media" {...orderMedia} />
      <Resource name="customers" {...customers} />
      <Resource name="customer-employees" {...customerEmployees} />
      <Resource name="reminders" {...reminders} />
      <Resource name="customer-offices" />
      <Resource name="indicators" {...indicators} />
      <Resource name="order-activity-categories" {...orderActivityCategories} />
      <Resource name="order-categories" {...orderCategories} />
      <Resource name="order-activities" {...orderActivities} />
      <Resource name="order-activity-sub-categories" />
      <Resource name="reminder-categories" {...reminderCategories} />
      <Resource name="document-categories" {...documentCategories} />
      <Resource name="reminder-years" />
      <Resource name="reminder-months" />
      <Resource name="order-activity-progress" {...orderActivityProgress} />
      <Resource name="users" {...users} />
      <Resource name="language-messages" {...languageMessages} />
      <Resource name="roles" />
      <Resource name="resources" />
      <Resource name="groups" {...groups} />
      <Resource name="languages" />
      <Resource name="profile" />
      <Resource name="quantity-types" />
      <Resource name="reminder-categories-groups" />
      <Resource name="reminders-groups" />
      <Resource name="documents" {...documents} />
    </Admin>
  );
};
export default App;
