import { downloadCSV } from "react-admin";
import { get } from "lodash";
import jsonExport from "jsonexport/dist";

const exporter = (data, indicators, translate) => {
  const columnName = (name) =>
    translate(`resources.order-indicators.fields.${name}`);
  const formatNumber = (n) => (n !== null ? n.toString().replace(".", ",") : n);
  const ordersToExport = data.map((order) => ({
    [columnName("year")]: get(order, "year"),
    [columnName("number")]: get(order, "number"),
    [columnName("customer")]: get(order, "customer_name"),
    [columnName("description")]: get(order, "description"),
    [columnName("owner")]: get(order, "owner_name"),
    [columnName("amount")]: formatNumber(get(order, "amount")),
    ...indicators.reduce(
      (map, indicator) => ({
        ...map,
        [indicator.name]: formatNumber(get(order, indicator.code, null)),
      }),
      {}
    ),
  }));
  jsonExport(
    ordersToExport,
    {
      rowDelimiter: ";",
      headers: [
        columnName("year"),
        columnName("number"),
        columnName("customer"),
        columnName("description"),
        columnName("owner"),
        columnName("amount"),
      ].concat(indicators.map((indicator) => indicator.name)),
    },
    (err, csv) => downloadCSV("\uFEFF" + csv, "orders")
  );
};

export default exporter;
