import {
  FieldTitle,
  InputHelperText,
  useInput,
  useTranslate,
} from "react-admin";
import React, { useMemo } from "react";
import { TextField, makeStyles } from "@material-ui/core";

import { Autocomplete } from "@material-ui/lab";
import classnames from "classnames";
import { get } from "lodash";
import { useFormState } from "react-final-form";

const useStyles = makeStyles((theme) => ({
  root: {
    // marginBottom: theme.spacing(1),
    // minWidth: 250,
    // maxHeight: 39,
    // "& > div:first-child > div": {
    //   paddingTop: theme.spacing(2.5),
    // },
  },
}));
const useGlobalStyles = makeStyles((theme) => ({
  // root: {
  //   marginBottom: theme.spacing(2),
  // },
  formField: {
    width: "100%",
    minWidth: 250,
    marginBottom: theme.spacing(1),
  },
}));
const AutocompleteInput = ({
  choices,
  optionText = "name",
  helperText = null,
  className,
  resource,
  source,
  setFilter,
  label,
  ...props
}) => {
  const formState = useFormState();
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const {
    input: { name, onChange, ...rest },
    meta: { touched, error },
    isRequired,
  } = useInput(props);
  const translate = useTranslate();
  const helperTextContent = useMemo(() => {
    return translate(helperText);
  }, [helperText, translate]);
  const selectedValues = useMemo(() => {
    const currentSelectedValues = get(formState, `values.${source}`);

    let selection = choices.filter(
      (c) => c && c.id && currentSelectedValues === c.id
    );
    return get(selection, "[0]", null);
  }, [formState, source, choices]);
  const handleChange = (_, values) => {
    onChange(get(values, "id"));
  };
  const getOptionLabel = (option) => {
    return typeof optionText === "function"
      ? optionText(option)
      : get(option, optionText);
  };
  return (
    <div className={classnames(className, globalClasses.formField)}>
      <Autocomplete
        {...rest}
        classes={classes}
        options={choices}
        onChange={handleChange}
        value={selectedValues}
        getOptionLabel={getOptionLabel}
        disabled={props.disabled}
        renderInput={(params) => (
          <TextField
            error={!!error}
            onChange={(e) => setFilter(e.target.value)}
            helperText={
              (helperText !== false && helperText !== null) ||
              (touched && error) ? (
                <InputHelperText
                  style={{ marginBottom: 32 }}
                  helperText={helperTextContent}
                  touched={touched}
                  error={error}
                />
              ) : null
            }
            required={isRequired}
            label={
              <FieldTitle
                label={label}
                source={source}
                resource={resource}
                isRequired={isRequired}
              />
            }
            variant={"filled"}
            {...params}
          />
        )}
      />
    </div>
  );
};

export default AutocompleteInput;
