import { DateInput, Filter, SearchInput } from "react-admin";

import MonthInput from "./inputs/MonthInput";
import React from "react";
import ReminderCategoryInput from "./inputs/ReminderCategoryInput";
import StatusInput from "./inputs/StatusInput";
import YearInput from "./inputs/YearInput";
import { useFiltersStyles } from "../components/lists/useStyles";
import GroupsInput from "./inputs/GroupsInput";
import CustomerInput from "../components/inputs/CustomerInput";
import CustomerOfficeInput from "./inputs/CustomerOfficeInput";
import { get } from "lodash";

const Filters = ({ isCustomer, ...props }) => {
  const classes = useFiltersStyles();
  const customerIdFiltered = get(props.filterValues, "customer_id", null);
  const showCustomerOffice = customerIdFiltered !== null ? true : false;

  return (
    <Filter {...props} classes={classes}>
      <SearchInput source="q" alwaysOn fullWidth />
      <StatusInput
        source="status"
        alwaysOn
        fullWidth
        allowEmpty
        emptyText="ra.action.all"
      />
      <ReminderCategoryInput source="reminder_category_id" alwaysOn fullWidth />
      <DateInput source="expiry_date_gte" fullWidth />
      <DateInput source="expiry_date_lte" fullWidth />
      <DateInput source="reminder_date_gte" fullWidth />
      <DateInput source="reminder_date_lte" fullWidth />
      <YearInput
        source="reminder_date_year_eq"
        column="reminder_date"
        fullWidth
      />
      {props.filterValues?.reminder_date_year_eq > 0 && (
        <MonthInput
          filterValues={{ year: props.filterValues?.reminder_date_year_eq }}
          source="reminder_date_month_eq"
          column="reminder_date"
          fullWidth
        />
      )}
      <YearInput source="expiry_date_year_eq" column="expiry_date" fullWidth />
      {props.filterValues?.expiry_date_year_eq > 0 && (
        <MonthInput
          filterValues={{ year: props.filterValues?.expiry_date_year_eq }}
          source="expiry_date_month_eq"
          column="expiry_date"
          fullWidth
        />
      )}
      {!isCustomer && <CustomerInput source="customer_id" />}
      {showCustomerOffice && (
        <CustomerOfficeInput source="customer_office_id" />
      )}
      <GroupsInput source="groups" />
    </Filter>
  );
};
export default Filters;
