/**
 * https://material-ui.com/components/material-icons/
 */
import { NotificationsIcon } from "../icons";
import List from "./List";

export default {
	options: {
		group: "dashboard",
		roles: ["admin", "user"],
	},
	icon: NotificationsIcon,
	list: List,
	create: () => {},
};
