import React, { useMemo } from "react";
import { SimpleForm, required } from "react-admin";

import TextInput from "../components/inputs/TextInput";
import useValidatableSave from "../components/forms/useValidatableSave";

const Form = ({ ...props }) => {
  const save = useValidatableSave({ ...props, redirect: undefined });
  const disabled = useMemo(
    () => props?.record?.id > 0 && props?.record?.id <= 3,
    [props]
  );
  return (
    <SimpleForm {...props} save={save}>
      <TextInput
        source="code"
        maxLength={45}
        validate={[required()]}
        disabled={disabled}
      />
      <TextInput
        source="name"
        maxLength={45}
        validate={[required()]}
        disabled={disabled}
      />
    </SimpleForm>
  );
};
export default Form;
