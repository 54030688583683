import ListField, { makeRedirect } from "../../components/fields/ListField";
import { TextField, useRedirect } from "react-admin";

import CloneButton from "../buttons/CloneButton";
import IdField from "../../order-activities/fields/IdField";
import LongTextField from "../../components/fields/LongTextField";
import MoneyField from "../../components/fields/MoneyField";
import React from "react";
import { get } from "lodash";
import useFieldLabel from "../../components/useFieldLabel";
import { useFormState } from "react-final-form";

const ActivitiesField = ({ planned, ...props }) => {
  const redirect = useRedirect();
  const formState = useFormState();
  const orderCategoryId = get(formState.values, "order_category_id");
  const fieldLabel = useFieldLabel({ resource: "order-activities" });
  const id = get(props, "record.id", 0);
  if (id <= 0) {
    return null;
  }
  return (
    <ListField
      {...props}
      reference="order-activities"
      target="order_id"
      filter={{ is_planned: planned }}
      canModify={true}
      canCreate={true}
      canRemove={true}
      additionalData={{
        is_planned: planned,
        order_category_id: orderCategoryId,
      }}
      sort={{ field: "id", order: "ASC" }}
      removeRedirect={() => redirect(makeRedirect({ ...props, tab: 1 }))}
    >
      <IdField label="ID" source="id" />
      <LongTextField
        source="order_category.name"
        sortBy="OrderCategories.name"
        label={fieldLabel("order_category_id")}
      />
      <LongTextField
        source="order_activity_category.name"
        sortBy="OrderActivityCategories.name"
        label={fieldLabel("order_activity_category_id")}
        minWidth={150}
      />
      <LongTextField
        source="order_activity_sub_category.name"
        sortBy="OrderActivitySubCategories.name"
        label={fieldLabel("order_activity_sub_category_id")}
        minWidth={150}
      />

      <LongTextField source="notes" label={fieldLabel("notes")} />
      <MoneyField source="total_cost" sortBy="total_cost" />
      <TextField
        label={fieldLabel("user_id")}
        source="user.nice_name"
        sortBy="Users.username"
      />
      {planned && (
        <CloneButton label="resources.order-activities.buttons.work" />
      )}
    </ListField>
  );
};

export default ActivitiesField;
