import { downloadCSV } from "react-admin";
import { get } from "lodash";
import jsonExport from "jsonexport/dist";

const exporter = (data, translate) => {
  const columnName = (name) => translate(`resources.customers.fields.${name}`);
  const ordersToExport = data.map((customer) => ({
    [columnName("id")]: get(customer, "id"),
    [columnName("name")]: get(customer, "name"),
    [columnName("vat_code")]: get(customer, "vat_code"),
    [columnName("sdi_code")]: get(customer, "sdi_code"),
    [columnName("email")]: get(customer, "email"),
    [columnName("phone")]: get(customer, "phone"),
    [columnName("referent")]: get(customer, "referent"),
  }));
  jsonExport(
    ordersToExport,
    {
      rowDelimiter: ";",
      headers: [
        columnName("id"),
        columnName("name"),
        columnName("vat_code"),
        columnName("sdi_code"),
        columnName("email"),
        columnName("phone"),
        columnName("referent"),
      ],
    },
    (err, csv) => downloadCSV("\uFEFF" + csv, "customers")
  );
};

export default exporter;
