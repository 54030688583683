import React, { useEffect } from "react";
import { useForm, useFormState } from "react-final-form";

import MoneyInput from "../../components/inputs/MoneyInput";
import { get } from "lodash";
import { useQueryWithStore } from "ra-core";

const CostInputWrapper = ({
  isAdmin,
  orderActivitySubCategoryId,
  ...props
}) => {
  const { record } = props;
  const form = useForm();
  const { loading, loaded, data } = useQueryWithStore({
    type: "getOne",
    resource: "order-activity-sub-categories",
    payload: {
      id: orderActivitySubCategoryId,
    },
  });
  const defaultCost = get(data, "default_cost", null);
  useEffect(() => {
    if (
      !get(record, "id") ||
      orderActivitySubCategoryId !== record.order_activity_sub_category_id
    ) {
      form.change("cost", defaultCost);
    }
  }, [form, defaultCost, orderActivitySubCategoryId, record]);

  if (loading || !loaded || (!isAdmin && defaultCost !== null)) {
    return null;
  }

  return <MoneyInput {...props} defaultValue={defaultCost} />;
};

const CostInput = (props) => {
  const formState = useFormState();
  const orderActivitySubCategoryId = get(
    formState.values,
    "order_activity_sub_category_id",
    0
  );
  if (orderActivitySubCategoryId === 0) {
    return null;
  }
  return (
    <CostInputWrapper
      {...props}
      orderActivitySubCategoryId={orderActivitySubCategoryId}
    />
  );
};
export default CostInput;
