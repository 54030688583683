import { Chip, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0.5),
  },
}));

const GroupsField = (props) => {
  const groups = props?.record?.groups;
  const classes = useStyles();

  return groups?.map((group, index) => (
    <Chip className={classes.root} key={index} label={group.name} />
  ));
};
export default GroupsField;
