import {
  Badge,
  Divider,
  List,
  Typography,
  makeStyles,
} from "@material-ui/core";
import {
  MenuItemLink,
  Responsive,
  getResources,
  useGetIdentity,
  useTranslate,
} from "react-admin";
import React, { createElement, useState } from "react";
import { isExpanded, isSelected } from "./config/sidebar";

import SubMenu from "./SubMenu";
import SubMenuIcon from "@material-ui/icons/ChevronRight";
import { VERSION } from "../../config";
import { connect } from "react-redux";
import useMenu from "./hook/useMenu";
import { withRouter } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    marginRight: theme.spacing(1),
    paddingTop: theme.spacing(1),
    boxShadow: theme.shadows[24],
    [theme.breakpoints.up("sm")]: {
      height: "100%",
    },
    [theme.breakpoints.down("md")]: {
      paddingBottom: "6em",
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  version: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(2),
    float: "right",
  },
}));

const SafeBadge = ({ titleAccess, children, ...props }) => (
  <Badge {...props}>{children}</Badge>
);

const MenuContent = ({ location, open, menu, onMenuClick }) => {
  const translate = useTranslate();
  const [visibility, setVisibility] = useState(
    menu.reduce(
      (array, group) => ({
        ...array,
        [group.label]: isExpanded(location, group) || group.expanded,
      }),
      {}
    )
  );
  const handleGroupToggle = (group) =>
    setVisibility({
      ...visibility,
      [group.label]: !visibility[group.label],
    });

  return menu.map((group, idx) => (
    <SubMenu
      key={idx}
      isOpen={visibility[group.label] !== false}
      sidebarIsOpen={open}
      name={`menu.groups.${group.label}`}
      handleToggle={handleGroupToggle.bind(this, group)}
      icon={<SubMenuIcon />}
    >
      {group.content.map((resource, idx) => (
        <MenuItemLink
          key={idx}
          to={resource.to}
          isActive={(_, location) => isSelected(location, resource)}
          primaryText={
            resource.localize !== false
              ? translate(`menu.items.${resource.label}`)
              : resource.label
          }
          onClick={onMenuClick}
          leftIcon={
            resource.badge && resource.badge.show ? (
              <SafeBadge
                color={resource.badge.type}
                variant={resource.badge.variant}
                badgeContent={resource.badge.value}
              >
                {createElement(resource.icon)}
              </SafeBadge>
            ) : (
              createElement(resource.icon)
            )
          }
          button
        />
      ))}
    </SubMenu>
  ));
};

const MenuWrapper = ({ hasDashboard = true, logout, ...props }) => {
  const menu = useMenu(hasDashboard);
  const classes = useStyles();
  return (
    <List component="nav" className={classes.root}>
      {menu.length > 0 && <MenuContent {...props} menu={menu} />}
      <Divider />
      <Typography variant="caption" className={classes.version}>
        IN_GEST21 - {VERSION}
      </Typography>
      <Responsive small={logout} medium={null} />
    </List>
  );
};

const Menu = (props) => {
  const { loading, loaded, identity } = useGetIdentity();
  if (
    loading ||
    !loaded ||
    !identity ||
    identity === null ||
    identity.id <= 0
  ) {
    return null;
  }

  return <MenuWrapper {...props} />;
};

const mapStateToProps = (state) => ({
  open: state.admin.ui.sidebarOpen,
  resources: getResources(state),
});

export default withRouter(connect(mapStateToProps)(Menu));
