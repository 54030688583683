import { API_URL } from "./config";
import { useState, useEffect } from "react";

const useLanguages = () => {
  const [data, setData] = useState({ loading: true, languages: null });
  useEffect(() => {
    let headers = new Headers();
    headers.append("Accept", "application/json");
    headers.append("Content-Type", "application/json");
    fetch(`${API_URL}/languages/load`, { headers })
      .then((response) => response.json())
      .then(({ data }) => setData({ loading: false, languages: data }));
  }, []);

  return data;
};

export default useLanguages;
