import { Filter, SearchInput } from "react-admin";

import React from "react";
import { useFiltersStyles } from "../components/lists/useStyles";

const Filters = (props) => {
  const classes = useFiltersStyles();
  return (
    <Filter {...props} classes={classes}>
      <SearchInput source="q" alwaysOn fullWidth />
    </Filter>
  );
};
export default Filters;
