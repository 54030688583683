import { BooleanInput, TextInput, required } from "react-admin";
import React, { Fragment } from "react";
import { useForm, useFormState } from "react-final-form";

import { Grid } from "@material-ui/core";
import GridSingle from "../../components/GridSingle";
import { get } from "lodash";

const UserInput = ({ ...props }) => {
  return (
    <React.Fragment>
      <GridSingle md={5}>
        <TextInput
          source="old_password"
          type="password"
          validate={[required()]}
          fullWidth
          {...props}
        />
      </GridSingle>
      <GridSingle md={5}>
        <TextInput
          source="password"
          label="resources.users.fields.new_password"
          type="password"
          fullWidth
          validate={[required()]}
          {...props}
        />
      </GridSingle>
      <GridSingle md={5}>
        <TextInput
          source="new_password_confirm"
          type="password"
          fullWidth
          validate={[required()]}
          {...props}
        />
      </GridSingle>
    </React.Fragment>
  );
};

const PasswordInput = ({ create = false, permissions, ...props }) => {
  const formState = useFormState();
  const changePassword = get(formState.values, `change_password`, false);
  const form = useForm();
  return (
    <Fragment>
      <BooleanInput
        source="change_password"
        disabled={create}
        defaultValue={create}
        onChange={(canChange) => {
          if (!canChange) {
            form.batch(() => {
              form.change("password", undefined);
              form.change("old_password", undefined);
              form.change("new_password", undefined);
              form.change("new_password_confirm", undefined);
            });
          }
        }}
        {...props}
      />
      {changePassword && (
        <Grid container>
          {permissions &&
          typeof permissions === "function" &&
          permissions(["admin", "developer", "store_manager"]) ? (
            <GridSingle md={5}>
              <TextInput
                source="password"
                type="password"
                fullWidth
                {...props}
              />
            </GridSingle>
          ) : (
            <UserInput {...props} />
          )}
        </Grid>
      )}
    </Fragment>
  );
};

export default PasswordInput;
