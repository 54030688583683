import * as icons from "@material-ui/icons";

import { Grid, makeStyles } from "@material-ui/core";
import { Loading, Title, useDataProvider } from "react-admin";
import React, { useEffect, useState } from "react";
import { useGetIdentity, useVersion } from "ra-core";

import Indicator from "./Indicator";
import List from "./List";
import Table from "./Table";
import { connect } from "react-redux";
import { get } from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(2),
    },
  },
  loader: {
    maxHeight: 400,
  },
}));
const Dashboard = ({ dispatch }) => {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const version = useVersion();
  const dataProvider = useDataProvider();
  const { identity } = useGetIdentity();
  const [state, setState] = useState({
    data: {
      indicators: [],
      tables: [],
    },
  });

  const { data } = state;
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const { data } = await dataProvider.getStats();
      setState(data);
      setLoading(false);
    }
    if (get(identity, "id", 0) > 0) {
      fetchData();
    }
  }, [dataProvider, dispatch, version, identity]);
  if (loading) {
    return <Loading className={classes.loader} />;
  }
  return (
    <div className={classes.root}>
      <Title title={"menu.items.dashboard"} />
      <Grid container>
        {get(data, "indicators", []).map((indicator, idx) => (
          <Grid key={idx} item xl={2} lg={3} md={4} sm={6} xs={12}>
            <Indicator
              color={indicator.color}
              label={`dashboard.${indicator.code}`}
              value={indicator.value}
              icon={icons[indicator.icon]}
            />
          </Grid>
        ))}
      </Grid>
      <Grid container>
        {get(data, "tables", []).map((table, idx) => (
          <Grid key={idx} item {...get(table, "disposition")}>
            {table.type === "list" && <List {...table} />}
            {table.type === "table" && <Table {...table} />}
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default connect()(Dashboard);
