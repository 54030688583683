import { DateField, EditButton, List as RaList, TextField } from "react-admin";
import React, { Fragment } from "react";
import { useListStyles, useSelector } from "../components/lists/useStyles";

import Filters from "./Filters";
import HelpProvider from "../components/HelpProvider";
import ResponsiveList from "../components/lists/ResponsiveList";
import perPage from "../utils/per-page";
import GroupsField from "./fields/GroupsField";

const List = (props) => {
  const sidebarOpen = useSelector((state) => state.admin.ui.sidebarOpen);
  const classes = useListStyles({ sidebarOpen });
  return (
    <Fragment>
      <HelpProvider />
      <RaList
        {...props}
        classes={classes}
        perPage={perPage(25, 25)}
        filters={<Filters />}
        exporter={false}
        sort={{ field: "created", order: "DESC" }}
      >
        <ResponsiveList
          mobileHeader={
            <Fragment>
              <TextField source="name" variant="subtitle2" />
            </Fragment>
          }
          mobileBody={
            <Fragment>
              <DateField source="created" showTime />
              <DateField source="modified" showTime />
            </Fragment>
          }
          mobileActions={
            <Fragment>
              <EditButton />
            </Fragment>
          }
        >
          <TextField source="id" />
          <TextField source="name" variant="subtitle2" />
          <GroupsField source="groups" sortable={false} />
          <DateField source="modified" showTime />
          <EditButton />
        </ResponsiveList>
      </RaList>
    </Fragment>
  );
};
export default List;
