import React from "react";
import { BooleanInput, Filter, SearchInput } from "react-admin";
import { useFiltersStyles } from "../components/lists/useStyles";

const Filters = (props) => {
	const classes = useFiltersStyles();
	return (
		<Filter {...props} classes={classes}>
			<SearchInput source="q" alwaysOn fullWidth />
			<BooleanInput
				source="not_readed"
				label="resources.notifications.fields.readed.empty"
				alwaysOn
			/>
		</Filter>
	);
};
export default Filters;
