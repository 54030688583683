import { ArrayInput, SimpleFormIterator } from "react-admin";

import React from "react";
import TextInput from "../../components/inputs/TextInput";
import { makeStyles } from "@material-ui/core";
import useFieldLabel from "../../components/useFieldLabel";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > label > span": {
      fontSize: 24,
    },
    "& ul": {
      marginTop: theme.spacing(4),
      borderTop: 1,
      borderTopStyle: "solid",
      borderTopColor: theme.palette.secondary[500],
    },
  },
  input: {
    display: "inline-grid",
    float: "left",
    minWidth: 260,
    marginRight: theme.spacing(2),
  },
}));
const OfficesInput = (props) => {
  const classes = useStyles();
  const fieldLabel = useFieldLabel({ resource: "customer-offices" });

  return (
    <ArrayInput {...props} classes={{ root: classes.root }}>
      <SimpleFormIterator>
        <TextInput
          source="name"
          label={fieldLabel("name")}
          maxLength={45}
          // validate={required()}
          className={classes.input}
        />
        <TextInput
          source="address"
          label={fieldLabel("address")}
          maxLength={45}
          // validate={required()}
          className={classes.input}
        />
        <TextInput
          source="city"
          label={fieldLabel("city")}
          maxLength={45}
          // validate={required()}
          className={classes.input}
        />
      </SimpleFormIterator>
    </ArrayInput>
  );
};
export default OfficesInput;
