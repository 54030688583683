import List from "./List";
import { OrderIndicatorIcon } from "../icons";

export default {
  options: {
    group: "dashboard",
    roles: ["developer", "admin"],
  },
  icon: OrderIndicatorIcon,
  list: List,
};
