import AutocompleteInput from "./AutocompleteInput";
import React from "react";
import { ReferenceInput } from "ra-ui-materialui";

const OrderInput = (props) => {
  return (
    <ReferenceInput {...props} reference="orders">
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
  );
};

export default OrderInput;
