import ListField, { makeRedirect } from "../../components/fields/ListField";
import { TextField, useRedirect } from "react-admin";

import DownloadButton from "../../order-media/buttons/DownloadButton";
import React from "react";
import { get } from "lodash";

const MediaField = ({ planned, ...props }) => {
  const redirect = useRedirect();
  const id = get(props, "record.id", 0);
  if (id <= 0) {
    return null;
  }
  return (
    <ListField
      {...props}
      reference="order-media"
      target="order_id"
      canModify={false}
      canCreate={true}
      canRemove={true}
      sort={{ field: "id", order: "ASC" }}
      removeRedirect={() => redirect(makeRedirect({ ...props, tab: 3 }))}
    >
      <TextField label="ID" source="id" />
      <TextField source="doc.filename" label="app.filename" />
      <DownloadButton label="" source="doc.file" />
    </ListField>
  );
};

export default MediaField;
