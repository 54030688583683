import { useQueryWithStore } from "ra-core";

export default ({ locale }) => {
  const { loaded, data } = useQueryWithStore({
    type: "getList",
    resource: `pages/menu`,
    payload: {
      filter: {
        language: locale,
      },
      sort: { field: "order", order: "desc" },
      pagination: {
        page: 1,
        perPage: 10,
      },
    },
  });
  return loaded ? data : null;
};
