import { NumberInput, SelectInput } from "react-admin";

import React from "react";
import { makeStyles } from "@material-ui/core";

const MANAGEMENT_COST_TYPES = ["euro", "perc"].map((id) => ({
  id,
  name: `resources.orders.management_cost_type.${id}`,
}));

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
  },
  input: {
    marginRight: theme.spacing(1),
  },
}));

const ManagementCostInput = ({ className, fullWidth, ...props }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <NumberInput
        {...props}
        source="management_cost"
        className={classes.input}
      />
      <SelectInput
        {...props}
        source="management_cost_type"
        choices={MANAGEMENT_COST_TYPES}
      />
    </div>
  );
};
export default ManagementCostInput;
