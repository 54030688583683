import * as React from "react";
import * as Validation from "../../utils/validation";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import {
  Notification,
  useDataProvider,
  useNotify,
  useRedirect,
  useTranslate,
} from "react-admin";

import TextInput from "../../components/pages/input/TextInput";
import { set } from "lodash";

const styles = (theme) => ({
  dialogContent: {
    minWidth: 300,
  },
});
const useStyles = makeStyles(styles);
const PasswordChangePage = () => {
  const [state, setState] = React.useState({
    data: {
      old_password: "",
      new_password: "",
      new_password_confirm: "",
    },
    loading: false,
    errors: {},
  });
  const translate = useTranslate();
  const classes = useStyles();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const handleCancel = () => redirect("/");
  const handleSubmit = () => {
    setState({ ...state, loading: true });
    const errors = Validation.validate(state.data, fields, translate);
    if (Validation.hasErrors(errors)) {
      setState({
        ...state,
        errors,
      });
      return;
    }
    dataProvider
      .update("users/password-change", { data: state.data })
      .then((response) => {
        notify(translate("ra.password_change.update_success"));
        redirect("/");
      })
      .catch(
        ({
          message,
          body: {
            data: { code, errors },
          },
        }) => {
          setState((state) => ({ ...state, loading: false }));
          if (code === 422) {
            notify(message, "warning");
          } else {
            notify(message);
          }
        }
      );
  };

  const change = (name, value, error) => {
    const errors = Validation.mapErrors(state.errors, name, error);
    setState({
      ...state,
      data: set(state.data, name, value),
      errors,
    });
  };

  const props = (props, errors = {}) => ({
    change,
    record: state.data,
    validate: Validation.required,
    error: errors[props.name] || null,
    ...props,
  });

  const field = (
    name,
    validate = Validation.required,
    Input = TextInput,
    props = {}
  ) => ({
    name,
    Input,
    props: { validate, name, prefix: "ra.password_change.fields", ...props },
  });
  const validateMatch = (value) => {
    let valid = Validation.required(value);
    if (valid !== true) {
      return valid;
    }
    let match = value === state.data.new_password;
    if (match === false) {
      return "ra.validation.password_not_matching";
    }
    return true;
  };
  const fields = [
    field("old_password", Validation.required, TextInput, {
      type: "password",
      autoFocus: true,
    }),
    field("new_password", Validation.required, TextInput, { type: "password" }),
    field("new_password_confirm", validateMatch, TextInput, {
      type: "password",
    }),
  ];
  return (
    <Dialog open maxWidth="lg">
      <DialogTitle>{translate("ra.password_change.title")}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {fields.map((field) => (
          <field.Input key={field.name} {...props(field.props, state.errors)} />
        ))}
        <Notification />
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={handleCancel}
          disabled={state.loading}
        >
          {translate("ra.action.cancel")}
        </Button>
        <Button color="primary" onClick={handleSubmit} disabled={state.loading}>
          {translate("ra.password_change.button")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default PasswordChangePage;
