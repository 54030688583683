import React from "react";
import { SelectInput } from "react-admin";

const STATUSES = ["open", /*"working",*/ "closed"].map((id) => ({
  id,
  name: `resources.orders.status.${id}`,
}));

const StatusInput = (props) => {
  return (
    <SelectInput
      {...props}
      choices={STATUSES}
      optionText="name"
      emptyText="resources.orders.fields.status.all"
    />
  );
};
export default StatusInput;
