import { FileField, FileInput, useTranslate } from "react-admin";
import { FormHelperText, makeStyles } from "@material-ui/core";
import React, { Fragment } from "react";

import { ACCEPTED_FILE_TYPES } from "../../config";
import { get } from "lodash";

const useStyles = makeStyles((theme) => ({
  helperText: {
    paddingLeft: theme.spacing(2),
  },
}));

const FileFieldWrapper = (props) => {
  const { source, record } = props;
  const url = get(record, source);
  return <FileField {...props} source="url" record={{ ...record, url }} />;
};

const MediaInput = (props) => {
  const { resource, source } = props;
  const translate = useTranslate();
  const classes = useStyles();
  return (
    <Fragment>
      <FileInput {...props} multiple accept={ACCEPTED_FILE_TYPES.join(",")}>
        <FileFieldWrapper source="file.path" title="file.name" />
      </FileInput>
      <FormHelperText className={classes.helperText}>
        {translate(`resources.${resource}.fields.${source}.help`)}
      </FormHelperText>
    </Fragment>
  );
};
export default MediaInput;
