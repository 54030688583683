import {
  CheckboxGroupInput,
  ReferenceArrayInput,
  usePermissions,
} from "react-admin";
import { formatMany, parseMany } from "../../utils/parsing";

import React from "react";
import { grey } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core";

const RolesCheckboxes = ({ choices, ...props }) => {
  const { loading, permissions } = usePermissions();
  const isDeveloper = !loading && permissions && permissions(["developer"]);
  const finalChoices = isDeveloper
    ? choices
    : choices.filter((c) => c.code !== "developer");
  return (
    <CheckboxGroupInput {...props} choices={finalChoices} optionText="name" />
  );
};

const RolesInput = withStyles((theme) => ({
  root: {
    "& legend": {
      fontSize: 22,
      marginBottom: theme.spacing(2),
    },
    "& .MuiFormGroup-row": {
      borderTop: 1,
      borderTopStyle: "solid",
      borderTopColor: grey[400],
    },
  },
}))(({ classes, ...props }) => (
  <ReferenceArrayInput
    {...props}
    reference="roles"
    format={formatMany}
    parse={parseMany}
    fullWidth
    className={classes.root}
  >
    <RolesCheckboxes optionText="name" />
  </ReferenceArrayInput>
));

export default RolesInput;
