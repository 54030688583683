import {
  BooleanInput,
  DateInput,
  NumberInput,
  SimpleForm,
  minValue,
  required,
  usePermissions,
} from "react-admin";

import CategoryInput from "./inputs/CategoryInput";
import CostInput from "./inputs/CostInput";
import MediaInput from "./inputs/MediaInput";
import OrderCategoryInput from "../components/inputs/OrderCategoryInput";
import OrderInput from "../components/inputs/OrderInput";
import QuantityInput from "./inputs/QuantityInput";
import React from "react";
import ReferenceToolbar from "../components/forms/ReferenceToolbar";
import SubCategoryInput from "./inputs/SubCategoryInput";
import TextInput from "../components/inputs/TextInput";
import UserInput from "../components/inputs/UserInput";
import useValidatableSave from "../components/forms/useValidatableSave";

const Form = (props) => {
  const { record } = props;
  const { loaded, permissions } = usePermissions();
  const isAdmin = loaded && permissions(["admin", "developer"]);
  const save = useValidatableSave({
    ...props,
    redirect:
      isAdmin && record.order_id
        ? `/orders/${record.order_id}/1`
        : `/order-activities`,
  });

  return (
    <SimpleForm
      {...props}
      save={save}
      initialValues={{
        is_planned: isAdmin,
        is_paid: false,
        ...record,
      }}
      toolbar={
        <ReferenceToolbar
          canGoBack={isAdmin}
          backRedirect="/order-activities"
          backReference="orders"
          backReferenceTarget="order_id"
          backTab={1}
        />
      }
    >
      <OrderInput source="order_id" filter={{ status: "open" }} />
      {isAdmin && (
        <OrderCategoryInput source="order_category_id" validate={required()} />
      )}
      {isAdmin && <BooleanInput source="is_planned" defaultValue={false} />}
      <UserInput source="user_id" isAdmin={isAdmin} />
      <CategoryInput
        isAdmin={isAdmin}
        source="order_activity_category_id"
        validate={required()}
      />
      <SubCategoryInput
        isAdmin={isAdmin}
        source="order_activity_sub_category_id"
        validate={required()}
      />
      <DateInput source="date" />
      <QuantityInput source="quantity" validate={required()} />
      <CostInput source="cost" validate={required()} isAdmin={isAdmin} />
      <NumberInput
        source="multiplier"
        validate={[required(), minValue(1)]}
        defaultValue={1}
      />
      <TextInput source="notes" maxLength={4000} fullWidth multiline />
      <MediaInput source="media" fullWidth />
    </SimpleForm>
  );
};
export default Form;
