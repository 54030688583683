import { ReferenceInput, SelectInput } from "react-admin";

import React from "react";

const QuantityTypeInput = (props) => (
  <ReferenceInput
    reference="quantity-types"
    sort={{ field: "name", order: "ASC" }}
    {...props}
  >
    <SelectInput optionText="name" />
  </ReferenceInput>
);
export default QuantityTypeInput;
