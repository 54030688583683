import { Link } from "ra-ui-materialui";
import React from "react";
import { Typography } from "@material-ui/core";
import { get } from "lodash";

const OrderField = ({ source, record, basePath, resource, sortBy, ...props }) =>
  get(record, "id", 0) > 0 ? (
    <Typography {...props} component={Link} to={`/orders/${get(record, "id")}`}>
      {get(record, "number")}/{get(record, "year")}
    </Typography>
  ) : null;
export default OrderField;
