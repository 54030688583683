import React from "react";
import { ReferenceInput, SelectInput } from "react-admin";

const DocumentCategoryInput = (props) => (
  <ReferenceInput
    {...props}
    reference="document-categories"
    sort={{ field: "name", order: "ASC" }}
    perPage={1000}
  >
    <SelectInput optionText="name" />
  </ReferenceInput>
);
export default DocumentCategoryInput;
