import { Chip, makeStyles } from "@material-ui/core";
import React, { useMemo } from "react";
import get from "lodash/get";
import { useGetIdentity } from "react-admin";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0.5),
  },
}));

const GroupsField = ({ source, record, isCustomer }) => {
  const { loaded, identity } = useGetIdentity();
  const userGroups = loaded ? identity?.groups || [] : [];
  const classes = useStyles();
  const reminderGroups = get(record, source);
  const finalGroups = useMemo(
    () =>
      isCustomer
        ? reminderGroups.filter((reminderGroup) => {
            return (
              userGroups.length === 0 ||
              userGroups.filter((userGroup) => {
                return userGroup.id === reminderGroup.id;
              }).length > 0
            );
          })
        : reminderGroups,
    [reminderGroups, userGroups, isCustomer]
  );

  return finalGroups.map((finalGroup, index) => (
    <Chip className={classes.root} key={index} label={finalGroup.name} />
  ));
};

export default GroupsField;
