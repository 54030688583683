import {
  ListItem,
  ListItemText,
  Typography,
  makeStyles,
} from "@material-ui/core";

import React from "react";
import { get } from "lodash";
import { useTranslate } from "react-admin";

const useStyles = makeStyles({
  root: {
    padding: 0,
  },
});

const CustomerOfficesField = ({ record, source }) => {
  const classes = useStyles();
  const customerOffices = get(record, source, []);
  const translate = useTranslate();

  if (customerOffices.length === 0) {
    return <Typography variant="body2">{translate("ra.all")}</Typography>;
  }

  return customerOffices?.map((customerOffice, index) => (
    <ListItem className={classes.root} key={index} dense>
      <ListItemText primary={customerOffice.name} />
    </ListItem>
  ));
};
export default CustomerOfficesField;
