import Form from "./Form";
import { Create as RaCreate } from "react-admin";
import React from "react";
import { useFormStyles } from "../components/forms/useStyles";

const Create = ({ permissions, ...props }) => {
  const classes = useFormStyles();
  return (
    <RaCreate {...props} classes={classes} redirect="list">
      <Form permissions={permissions} create />
    </RaCreate>
  );
};

export default Create;
