import React from "react";
import { required, SimpleForm, TextInput, usePermissions } from "react-admin";
import useValidatableSave from "../components/forms/useValidatableSave";
import MediaInput from "./input/MediaInput";
import CustomerOfficesInput from "./input/CustomerOfficesInput";
import CustomerInput from "../components/inputs/CustomerInput";
import DocumentCategoryInput from "./input/DocumentCategoryInput";
import useFieldLabel from "../components/useFieldLabel";

const Form = (props) => {
  const save = useValidatableSave({ ...props, redirect: undefined });
  const fieldLabel = useFieldLabel({ ...props });
  const { loaded, permissions } = usePermissions();
  const isCustomer = loaded && permissions(["customer"]);
  const additionalProps = isCustomer ? { toolbar: null } : {};

  return (
    <SimpleForm {...props} {...additionalProps} save={save}>
      <TextInput
        disabled={isCustomer}
        source="name"
        maxLength={45}
        validate={required()}
      />
      <CustomerInput
        disabled={isCustomer}
        source="customer_id"
        fullWidth
        validate={required()}
      />
      <CustomerOfficesInput
        disabled={isCustomer}
        label={fieldLabel("customer_offices_id")}
        source="customer_offices"
      />
      <DocumentCategoryInput
        disabled={isCustomer}
        source="document_category_id"
        validate={required()}
      />
      <MediaInput disabled={isCustomer} source="media" validate={required()} />
    </SimpleForm>
  );
};

export default Form;
