import React, { useEffect } from "react";
import { useForm, useFormState } from "react-final-form";

import { InputAdornment } from "@material-ui/core";
import { NumberInput } from "ra-ui-materialui";
import { get } from "lodash";
import { useQueryWithStore } from "ra-core";

const QuantityInputWrapper = ({ orderActivitySubCategoryId, ...props }) => {
  const form = useForm();
  const { loading, loaded, data } = useQueryWithStore({
    type: "getOne",
    resource: "order-activity-sub-categories",
    payload: {
      id: orderActivitySubCategoryId,
    },
  });
  const isQuantityRequired = get(data, "is_quantity_required", true);
  useEffect(() => {
    if (!isQuantityRequired) {
      form.change("quantity", 1);
    }
  }, [form, isQuantityRequired]);
  if (loading || !loaded) {
    return null;
  }

  if (!isQuantityRequired) {
    return null;
  }
  const quantityType = get(data, "quantity_type.name");
  return (
    <NumberInput
      {...props}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">{quantityType}</InputAdornment>
        ),
      }}
    />
  );
};

const QuantityInput = (props) => {
  const formState = useFormState();
  const orderActivitySubCategoryId = get(
    formState.values,
    "order_activity_sub_category_id",
    0
  );
  if (orderActivitySubCategoryId === 0) {
    return null;
  }
  return (
    <QuantityInputWrapper
      {...props}
      orderActivitySubCategoryId={orderActivitySubCategoryId}
    />
  );
};
export default QuantityInput;
