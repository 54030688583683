import Create from "./Create";
import { CustomerEmployeeIcon } from "../icons";
import Edit from "./Edit";
import List from "./List";

export default {
  options: {
    group: "customers",
    roles: ["developer", "admin", "planner", "customer"],
  },
  icon: CustomerEmployeeIcon,
  list: List,
  edit: Edit,
  create: Create,
};
