import React, { Fragment } from "react";
import { useListStyles, useSelector } from "../components/lists/useStyles";
import { TextField, EditButton, List as RaList, DateField } from "react-admin";

import Filters from "./Filters";
import HelpProvider from "../components/HelpProvider";
import perPage from "../utils/per-page";
import ResponsiveList from "../components/lists/ResponsiveList";

const List = (props) => {
  const sidebarOpen = useSelector((state) => state.admin.ui.sidebarOpen);
  const classes = useListStyles({ sidebarOpen });
  return (
    <Fragment>
      <HelpProvider />
      <RaList
        {...props}
        classes={classes}
        perPage={perPage(25, 25)}
        filters={<Filters />}
        exporter={false}
        sort={{ field: "id", order: "ASC" }}
      >
        <ResponsiveList
          mobileHeader={
            <Fragment>
              <TextField source="id" />
              <TextField source="code" variant="subtitle2" />
            </Fragment>
          }
          mobileBody={
            <Fragment>
              <TextField source="name" variant="subtitle2" />
              <DateField source="created" showTime />
              <DateField source="modified" showTime />
            </Fragment>
          }
          mobileActions={
            <Fragment>
              <EditButton />
            </Fragment>
          }
        >
          <TextField source="id" />
          <TextField source="code" variant="subtitle2" />
          <TextField source="name" variant="subtitle2" />
          <DateField source="created" showTime />
          <DateField source="modified" showTime />
          <EditButton />
        </ResponsiveList>
      </RaList>
    </Fragment>
  );
};

export default List;
