import { ReferenceArrayInput, SelectArrayInput } from "react-admin";

import React from "react";

const GroupsInput = (props) => (
  <ReferenceArrayInput {...props} reference="groups" fullWidth>
    <SelectArrayInput optionText="name" />
  </ReferenceArrayInput>
);
export default GroupsInput;
