import { ReferenceInput, SelectInput } from "react-admin";

import React from "react";

const ReminderCategoryInput = (props) => (
  <ReferenceInput
    {...props}
    reference="reminder-categories"
    sort={{ field: "name", order: "ASC" }}
    perPage={1000}
  >
    <SelectInput optionText="name" style={{ minWidth: 250 }} />
  </ReferenceInput>
);

export default ReminderCategoryInput;
