import { downloadCSV } from "react-admin";
import { get } from "lodash";
import jsonExport from "jsonexport/dist";

const exporter = (data, translate) => {
  const columnName = (name) => translate(`resources.users.fields.${name}`);
  const ordersToExport = data.map((order) => ({
    [columnName("id")]: get(order, "id"),
    [columnName("profile.name")]: get(order, "profile.name"),
    [columnName("profile.surname")]: get(order, "profile.surname"),
    [columnName("email")]: get(order, "email"),
  }));
  jsonExport(
    ordersToExport,
    {
      rowDelimiter: ";",
      headers: [
        columnName("id"),
        columnName("profile.name"),
        columnName("profile.surname"),
        columnName("email"),
      ],
    },
    (err, csv) => downloadCSV("\uFEFF" + csv, "users")
  );
};

export default exporter;
