import { Filter as RaFilter, SearchInput } from "react-admin";

import React from "react";
import RoleInput from "./inputs/RoleInput";
import StatusInput from "./inputs/StatusInput";
import { useFiltersStyles } from "../components/lists/useStyles";
import GroupsInput from "./inputs/GroupInput";
import CustomerOfficeInput from "./inputs/CustomerOfficeInput";
import useFieldLabel from "../components/useFieldLabel";
import CustomerInput from "../components/inputs/CustomerInput";

const Filters = (props) => {
  const fieldLabel = useFieldLabel({ ...props });
  const classes = useFiltersStyles();
  return (
    <RaFilter {...props} classes={classes}>
      <SearchInput source="q" alwaysOn fullWidth />
      <StatusInput source="status" />
      <RoleInput source="role_id" />
      <CustomerInput label={fieldLabel("customer_id")} source="customer_id" />
      <GroupsInput label={fieldLabel("group_id")} source="group_id" />
      <CustomerOfficeInput
        label={fieldLabel("customer_office_id")}
        source="customer_office_id"
        alwaysOn
      />
    </RaFilter>
  );
};
export default Filters;
