import { Box, Card, Divider, Typography } from "@material-ui/core";

import React from "react";
import cartouche from "./CardWithIcon.png";
import cartoucheDark from "./CardWithIconDark.png";
import { createElement } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  card: {
    minHeight: 52,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    flex: "1",
    "& a": {
      textDecoration: "none",
      color: "inherit",
    },
  },
  main: {
    overflow: "inherit",
    padding: 16,
    color: "white",
    backgroundColor: theme.palette.primary[500],
    background: `url(${
      theme.palette.type === "dark" ? cartoucheDark : cartouche
    }) no-repeat`,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .icon": {
      color: theme.palette.type === "dark" ? "inherit" : "#dc2440",
    },
  },
  title: {
    color: "white",
  },
  children: {
    padding: theme.spacing(2),
  },
}));

const CardWithIcon = (props) => {
  const { icon, title, subtitle, children } = props;
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <div className={classes.main}>
        <Box width="3em" className="icon">
          {createElement(icon, { fontSize: "large" })}
        </Box>
        <Box textAlign="right">
          <Typography className={classes.title} color="textSecondary">
            {title}
          </Typography>

          {subtitle}
        </Box>
      </div>
      {children && <Divider />}
      <div className={classes.children}>{children}</div>
    </Card>
  );
};

export default CardWithIcon;
