import React from "react";
import { Typography } from "@material-ui/core";
import { get } from "lodash";

const formatter = (props, lang = "it-IT") => Intl.NumberFormat(lang, props);

const IndicatorField = ({
  source,
  record,
  indicator,
  basePath,
  label,
  ...props
}) => {
  switch (indicator.formatter) {
    case "euro":
      const moneyValue = parseFloat(get(record, source));
      return (
        <Typography variant="body2" {...props}>
          {!isNaN(moneyValue) &&
            formatter({
              style: "currency",
              currency: "EUR",
            }).format(moneyValue)}
        </Typography>
      );
    case "percent":
      const percentValue = parseFloat(get(record, source) || 0);
      return (
        <Typography variant="body2" {...props}>
          {!isNaN(percentValue) &&
            formatter({
              style: "percent",
              maximumFractionDigits: 2,
            }).format(percentValue / 100)}
        </Typography>
      );

    case "number":
      const floatValue = parseFloat(get(record, source) || 0);
      return (
        <Typography variant="body2" {...props}>
          {!isNaN(floatValue) &&
            formatter({
              maximumFractionDigits: 2,
            }).format(floatValue)}
        </Typography>
      );

    default:
      const intValue = parseInt(get(record, source), 10);
      return (
        <Typography variant="body2" {...props}>
          {!isNaN(intValue) === false ? 0 : intValue}
        </Typography>
      );
  }
};
export default IndicatorField;
