import {
  DeleteWithConfirmButton,
  Toolbar as RaToolbar,
  SaveButton,
} from "react-admin";

import PrintButton from "./buttons/PrintButton";
import React from "react";
import { get } from "lodash";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    clear: "both",
    justifyContent: "space-between",
  },
}));
const Toolbar = (props) => {
  const classes = useStyles();
  const id = get(props, "record.id", 0);
  return (
    <RaToolbar {...props} className={classes.root}>
      <SaveButton submitOnEnter={true} disabled={props.pristine} />
      <PrintButton />
      {id > 0 && (
        <DeleteWithConfirmButton
          confirmTitle="resources.product-activities.title.confirm_delete"
          confirmContent="resources.product-activities.message.confirm_delete"
        />
      )}
    </RaToolbar>
  );
};

export default Toolbar;
