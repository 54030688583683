import {
  Badge,
  Button,
  ClickAwayListener,
  Divider,
  Grow,
  IconButton,
  Link,
  List,
  ListItem,
  Paper,
  Popper,
  Tooltip,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";

import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import NotificationMessage from "./NotificationMessage";
import { NotificationsIcon } from "../../icons";
import React from "react";
import { useTranslate } from "ra-core";

const useStyles = makeStyles((theme) => ({
  paper: {
    transformOrigin: "top right",
    marginTop: -theme.spacing(1),
  },
  list: {
    width: theme.spacing(40),
    maxHeight: theme.spacing(40),
    overflow: "auto",
    paddingTop: 0,
    paddingBottom: 0,
    borderRadius: theme.spacing(0.5),
  },
  divider: {
    margin: theme.spacing(0),
  },
}));
const NotificationPopup = ({ unreaded, messages }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const [open, setOpen] = React.useState(false);
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
    setTooltipOpen(false);
  };
  const handleOpen = () =>
    isSmall
      ? (document.location.href = "#/notifications")
      : setTooltipOpen(!open);

  return (
    <React.Fragment>
      <Tooltip
        open={tooltipOpen}
        onOpen={handleOpen}
        onClose={() => {
          setTooltipOpen(false);
        }}
        title={translate("resources.notifications.name")}
        enterDelay={300}
      >
        <IconButton
          color="inherit"
          ref={anchorRef}
          aria-controls={open ? "notifications-popup" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          data-ga-event-category="AppBar"
          data-ga-event-action="toggleNotifications"
        >
          <Badge color="primary" badgeContent={unreaded}>
            <NotificationsIcon />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popper
        id="notifications-popup"
        anchorEl={anchorRef.current}
        open={open}
        placement="bottom-end"
        transition
        disablePortal
        role={undefined}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener
            onClickAway={() => {
              setOpen(false);
            }}
          >
            <Grow in={open} {...TransitionProps}>
              <Paper className={classes.paper}>
                <List className={classes.list}>
                  {messages.map((message, index) => (
                    <React.Fragment key={message.id}>
                      <NotificationMessage
                        onClick={handleToggle}
                        message={message}
                      />
                      {index < messages.length - 1 ? (
                        <Divider className={classes.divider} />
                      ) : null}
                    </React.Fragment>
                  ))}
                  <ListItem>
                    <Button
                      component={Link}
                      href="#/notifications"
                      onClick={handleToggle}
                      endIcon={<ArrowForwardIcon />}
                    >
                      {translate("resources.notifications.view_all")}
                    </Button>
                  </ListItem>
                </List>
              </Paper>
            </Grow>
          </ClickAwayListener>
        )}
      </Popper>
    </React.Fragment>
  );
};

export default NotificationPopup;
