import { BooleanField, DateField, TextField, useRedirect } from "react-admin";
import ListField, { makeRedirect } from "../../components/fields/ListField";

import LongTextField from "../../components/fields/LongTextField";
import MoneyField from "../../components/fields/MoneyField";
import React from "react";
import { get } from "lodash";
import useFieldLabel from "../../components/useFieldLabel";
import { useFormState } from "react-final-form";

const PaymentsField = (props) => {
  const redirect = useRedirect();
  const fieldLabel = useFieldLabel({ resource: "order-payments" });
  const formState = useFormState();
  const orderCategoryId = get(formState.values, "order_category_id");
  const id = get(props, "record.id", 0);
  if (id <= 0) {
    return null;
  }
  return (
    <ListField
      {...props}
      reference="order-payments"
      target="order_id"
      canModify={true}
      canCreate={true}
      canRemove={true}
      additionalData={{
        order_category_id: orderCategoryId,
      }}
      removeRedirect={() => redirect(makeRedirect({ ...props, tab: 2 }))}
    >
      <LongTextField
        source="order_category.name"
        sortBy="OrderCategories.name"
        label={fieldLabel("order_category_id")}
      />
      <TextField source="number" label={fieldLabel("number")} />
      <DateField source="date" label={fieldLabel("date")} />
      <MoneyField source="amount" label={fieldLabel("amount")} />
      <BooleanField source="is_paid" label={fieldLabel("is_paid")} />
      <LongTextField source="notes" label={fieldLabel("notes")} />
    </ListField>
  );
};

export default PaymentsField;
