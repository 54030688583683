import Create from "./Create";
import Edit from "./Edit";
import List from "./List";
import { OrderActivityCategoryIcon } from "../icons";

export default {
  icon: OrderActivityCategoryIcon,
  options: {
    group: "admin",
    roles: ["admin", "developer"],
  },
  list: List,
  edit: Edit,
  create: Create,
};
