import * as icons from "@material-ui/icons";

import { DateField, translate } from "react-admin";
import { Divider, Typography } from "@material-ui/core";

import Card from "@material-ui/core/Card";
import CardIcon from "./CardIcon";
import LongTextField from "../components/fields/LongTextField";
import MuiTable from "@material-ui/core/Table";
import React from "react";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import WarningIcon from "@material-ui/icons/Warning";
import compose from "recompose/compose";
import { get } from "lodash";
import { withStyles } from "@material-ui/core/styles";

const styles = () => ({
  main: {
    flex: "1",
    marginTop: 40,
    marginRight: "1em",
  },
  titleLink: { textDecoration: "none", color: "inherit" },
  card: {
    padding: "16px 0",
    overflow: "inherit",
    textAlign: "right",
  },
  title: {
    padding: "0 16px",
  },
  value: {
    padding: "0 16px",
    minHeight: 48,
  },
  listItem: {
    paddingBottom: 0,
  },
  listItemText: {
    overflowY: "hidden",
    height: "1.5em",
    fontFamily: "Fira Code, Consolas, Courier New",
  },
  warningBox: {
    textAlign: "center",
  },
  warningIcon: {
    fontSize: 50,
  },
});

const RENDERERS = {
  date: DateField,
  datetime: (props) => <DateField {...props} showTime />,
  long_text: LongTextField,
};

const Table = ({
  icon,
  code,
  color,
  weight = 500,
  columns = [],
  rows = [],
  translate,
  classes,
}) => (
  <div className={classes.main}>
    <CardIcon
      Icon={icons[icon] || icons.Dashboard}
      color={color}
      weight={weight}
    />
    <Card className={classes.card}>
      <Typography className={classes.title} color="textSecondary">
        {translate(`dashboard.${code}.title`)}
      </Typography>
      <Divider />
      <MuiTable padding="default">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={get(column, "name")}>
                {translate(`dashboard.${code}.columns.${get(column, "name")}`)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {(!rows || rows.length === 0) && (
            <TableRow>
              <TableCell
                colSpan={columns.length}
                className={classes.warningBox}
              >
                <WarningIcon className={classes.warningIcon} />
                <Typography variant="body2">
                  {translate("dashboard.no_data")}
                </Typography>
              </TableCell>
            </TableRow>
          )}
          {rows.map((row, index) => (
            <TableRow key={index}>
              {columns.map((column) => {
                const Component = get(RENDERERS, get(column, "renderer"), null);

                let content;
                if (Component !== null) {
                  content = (
                    <Component source={get(column, "name")} record={row} />
                  );
                } else {
                  content = get(row, get(column, "name"));
                }
                return (
                  <TableCell key={`col-${get(column, "name")}-${index}`}>
                    {content}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </MuiTable>
    </Card>
  </div>
);

const enhance = compose(withStyles(styles), translate);

export default enhance(Table);
