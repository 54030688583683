import React, { useEffect, useMemo } from "react";
import { ReferenceArrayInput, SelectArrayInput } from "react-admin";
import { useFormState, useForm } from "react-final-form";
import { get } from "lodash";
import { formatMany, parseMany } from "../../utils/parsing";

const CustomerOfficesInput = (props) => {
  const formState = useFormState({ subscription: { values: true } });
  const form = useForm();
  const customerId = get(formState.values, "customer_id", 0);

  const isCustomer = useMemo(() => {
    if (formState.values?.roles) {
      return formState.values.roles.some((r) => r.id === 6);
    }
    return false;
  }, [formState.values.roles]);

  useEffect(() => {
    form.change(props.source, []);
  }, [customerId, form, props.source, isCustomer]);

  if (customerId === 0) {
    return null;
  }
  if (!isCustomer) {
    return null;
  }

  return (
    <ReferenceArrayInput
      {...props}
      reference="customer-offices"
      filter={{ customer_id: customerId }}
      format={formatMany}
      parse={parseMany}
      fullWidth
      perPage={1000}
    >
      <SelectArrayInput optionText="name" />
    </ReferenceArrayInput>
  );
};
export default CustomerOfficesInput;
