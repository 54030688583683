import React from "react";
import { Filter, SearchInput } from "react-admin";
import { useFiltersStyles } from "../components/lists/useStyles";
import CustomerInput from "../components/inputs/CustomerInput";
import DocumentCategoryInput from "./input/DocumentCategoryInput";
import CustomerOfficeInput from "./input/CustomerOfficeInput";
import useFieldLabel from "../components/useFieldLabel";

const Filters = ({ isCustomer, ...props }) => {
  const classes = useFiltersStyles();
  const fieldLabel = useFieldLabel({ ...props });

  return (
    <Filter {...props} classes={classes}>
      <SearchInput source="q" alwaysOn fullWidth />
      {!isCustomer && <CustomerInput source="customer_id" fullWidth />}
      <CustomerOfficeInput
        label={fieldLabel("customer_office_id")}
        source="customer_office_id"
        fullWidth
        alwaysOn
        isCustomer={isCustomer}
      />
      <DocumentCategoryInput source="document_category_id" fullWidth />
    </Filter>
  );
};

export default Filters;
