import moment from "moment";
import polyglotI18nProvider from "ra-i18n-polyglot";
import englishMessages from "ra-language-english";
import italianMessages from "ra-language-italian";
import spanishMessages from "ra-language-spanish";
import { resolveBrowserLocale } from "react-admin";
const defaultMessages = {
	en: englishMessages,
	it: italianMessages,
	es: spanishMessages,
};
const i18nProvider = (languages) =>
	polyglotI18nProvider((locale) => {
		if (process.env.NODE_ENV !== "production") {
			localStorage.setItem("locale", locale);
		}
		moment.locale(locale);
		return {
			...defaultMessages[locale],
			...languages[locale],
		};
	}, resolveBrowserLocale());

export default i18nProvider;
