import React, { useEffect } from "react";
import { useForm, useFormState } from "react-final-form";

import { SelectInput } from "react-admin";
import { get } from "lodash";
import moment from "moment";

const STATUSES = ["open", /*"working",*/ "closed"].map((id) => ({
  id,
  name: `resources.orders.status.${id}`,
}));

const StatusInput = (props) => {
  const formState = useFormState();
  const form = useForm();
  const value = get(formState.values, props.source);
  const endAt = get(formState.values, "end_at");
  useEffect(() => {
    if (value === "closed" && endAt === null) {
      form.change("end_at", moment().format("YYYY-MM-DD"));
    }
  }, [value, form, endAt]);
  return (
    <SelectInput
      {...props}
      choices={STATUSES}
      optionText="name"
      emptyText="resources.orders.fields.status.all"
    />
  );
};
export default StatusInput;
