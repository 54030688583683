import { ReferenceInput, SelectInput } from "ra-ui-materialui";

import React from "react";
import { get } from "lodash";
import { useFormState } from "react-final-form";

const CustomerOfficeInput = (props) => {
  const formState = useFormState();
  const customerId = get(formState.values, "customer_id", 0);
  if (customerId === 0) {
    return null;
  }

  return (
    <ReferenceInput
      {...props}
      reference="customer-offices"
      filter={{ customer_id: customerId }}
      perPage={1000}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  );
};
export default CustomerOfficeInput;
