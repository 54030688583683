import { NumberInput, SelectInput, TextInput } from "ra-ui-materialui";

import React from "react";
import { get } from "lodash";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  expr: {
    marginRight: theme.spacing(1),
    width: "auto",
    minWidth: 80,
    maxWidth: 80,
  },
  numberInput: {
    width: "auto",
    "& label": {
      WebkitLineClamp: 1,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      maxWidth: 200,
    },
    "& .MuiInputLabel-shrink": {
      maxWidth: 250,
    },
  },
}));

const IndicatorInput = ({ indicator, ...props }) => {
  const classes = useStyles();
  const formatter = get(indicator, "formatter");
  switch (formatter) {
    case "number":
    case "euro":
      return (
        <div title={indicator.name}>
          <SelectInput
            className={classes.expr}
            label="Expr"
            defaultValue="="
            source={`${props.source}_expr`}
            choices={["=", ">=", ">", "<", "<="].map((id) => ({
              id,
              name: id,
            }))}
          />
          <NumberInput {...props} className={classes.numberInput} />
        </div>
      );
    default:
    case "text":
      return <TextInput {...props} />;
  }
};

export default IndicatorInput;
