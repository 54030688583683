import { TOGGLE_HELP_BLOCK } from "./helpReducer";
import { get } from "lodash";
import useCookie from "react-use-cookie";
import { useDispatch } from "react-redux";
import { useMemo } from "react";
import { useRoles } from "../authProvider";
import { useSelector } from "react-redux";
import { useTranslate } from "react-admin";

const useHelpProvider = (caller = "undefined") => {
  const translate = useTranslate();
  const url = useSelector((state) => {
    let url = get(state, "router.location.pathname", "/").substr(1);
    let slash = url.indexOf("/");
    if (slash !== -1) {
      const after = url.substr(slash + 1);
      url = url.substr(0, slash);
      if (!isNaN(parseInt(after, 10))) {
        url += "/edit";
      } else {
        url += "/create";
      }
    }
    return url;
  });
  const [cookie, setVisible] = useCookie(url, true);
  const visibleLocal = useSelector((state) =>
    get(state, `help.blocks.${url}`, cookie === true || cookie === "true")
  );

  const dispatch = useDispatch();

  const roles = useRoles();

  const response = useMemo(() => {
    if (!url) {
      return {
        hasHelp: false,
      };
    }
    const matches = roles
      .map((r) => r.code)
      .concat(["for_all"])
      .map((role) => {
        const titleKey = `app.help-block.title.${url}.${role}`;
        const messageKey = `app.help-block.message.${url}.${role}`;
        const title = translate(titleKey);
        const message = translate(messageKey);

        return {
          url,
          hasHelp: title !== titleKey || message !== messageKey,
          title: title !== titleKey ? title : undefined,
          message: message !== messageKey ? message : undefined,
          visible: visibleLocal,
          toggle: () => {
            console.info("visible:", !visibleLocal);
            setVisible(!visibleLocal);
            dispatch({
              type: TOGGLE_HELP_BLOCK,
              payload: { url },
            });
          },
        };
      });

    return (
      matches.find((m) => m.hasHelp) || {
        url,
        hasHelp: false,
      }
    );
  }, [roles, url, translate, setVisible, dispatch, visibleLocal]);
  return response;
};

export default useHelpProvider;
