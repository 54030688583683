import { ReferenceInput, SelectInput } from "react-admin";

import React from "react";

const OrderCategoryInput = ({ ...props }) => {
  return (
    <ReferenceInput
      {...props}
      reference="order-categories"
      sort={{ field: "name", order: "ASC" }}
      perPage={100}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  );
};
export default OrderCategoryInput;
