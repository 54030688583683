import * as colors from "@material-ui/core/colors";

import React, { useMemo } from "react";
import { useTheme, withStyles } from "@material-ui/core/styles";

import Card from "@material-ui/core/Card";

const styles = {
  card: {
    float: "left",
    margin: "-20px 20px 0 15px",
    zIndex: 100,
    borderRadius: 3,
  },
  icon: {
    float: "right",
    width: 54,
    height: 54,
    padding: 14,
    color: "#fff",
  },
};

const CardIcon = ({ Icon, classes, color, weight = 500 }) => {
  const theme = useTheme();
  const bgColor = useMemo(() => {
    if (colors[color]) {
      return colors[color];
    } else if (theme.palette[color]) {
      return theme.palette[color];
    } else return theme.palette.primary;
  }, [color, theme.palette]);
  return (
    <Card className={classes.card} style={{ backgroundColor: bgColor[weight] }}>
      <Icon className={classes.icon} />
    </Card>
  );
};
export default withStyles(styles)(CardIcon);
