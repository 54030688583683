import { SimpleForm, TextInput, required } from "react-admin";

import CustomerInput from "./inputs/CustomerInput";
import PasswordInput from "./inputs/PasswordInput";
import React from "react";
import RolesInput from "../components/inputs/RolesInput";
import GroupsInput from "../components/inputs/GroupsInput";
import StatusInput from "./inputs/StatusInput";
import useValidatableSave from "../components/forms/useValidatableSave";
import CustomerOfficesInput from "./inputs/CustomerOfficesInput";
import useFieldLabel from "../components/useFieldLabel";

const Form = ({ create = false, permissions, ...props }) => {
  const fieldLabel = useFieldLabel({ ...props });
  const save = useValidatableSave({ ...props, redirect: undefined });
  return (
    <SimpleForm {...props} save={save}>
      <StatusInput source="status" validate={required()} />
      <TextInput source="username" validate={required()} />
      <PasswordInput permissions={permissions} create={create} />
      <TextInput source="email" validate={required()} />
      <TextInput source="profile.name" validate={required()} />
      <TextInput source="profile.surname" validate={required()} />
      <RolesInput source="roles" validate={required()} />
      <GroupsInput source="groups" fullWidth />
      <CustomerInput source="customer_id" />
      <CustomerOfficesInput
        label={fieldLabel("customer_offices")}
        source="customer_offices"
        fullWidth
      />
    </SimpleForm>
  );
};
export default Form;
