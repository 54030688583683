import {
  DateField,
  EditButton,
  List as RaList,
  TextField,
  useTranslate,
} from "react-admin";
import React, { Fragment } from "react";
import { red, yellow } from "@material-ui/core/colors";
import { useListStyles, useSelector } from "../components/lists/useStyles";

import Filters from "./Filters";
import HelpProvider from "../components/HelpProvider";
import ReminderField from "./fields/ReminderField";
import ResponsiveList from "../components/lists/ResponsiveList";
import StatusField from "./fields/StatusField";
import exporter from "./exporter";
import { get } from "lodash";
import perPage from "../utils/per-page";
import useFieldLabel from "../components/useFieldLabel";
import GroupsField from "./fields/GroupsField";
import ReminderCategoryField from "./fields/ReminderCategoryField";
import CustomerEmployeeField from "./fields/CustomerEmployeeField";

const STATUS_COLORS = {
  pending: "#fff",
  expiring: yellow[50],
  expired: red[50],
};

const List = (props) => {
  const sidebarOpen = useSelector((state) => state.admin.ui.sidebarOpen);
  const translate = useTranslate();
  const classes = useListStyles({ sidebarOpen });
  const fieldLabel = useFieldLabel({ ...props });
  const isCustomer = props.permissions && props.permissions(["customer"]);
  const rowStyle = (record, index) => ({
    backgroundColor: get(STATUS_COLORS, record?.status, ""),
  });
  const additionalProps = isCustomer ? { bulkActionButtons: false } : {};
  return (
    <Fragment>
      <HelpProvider />
      <RaList
        {...props}
        {...additionalProps}
        classes={classes}
        perPage={perPage(25, 25)}
        hasCreate={!isCustomer}
        filters={<Filters isCustomer={isCustomer} />}
        filterDefaultValues={{
          status: "expiring",
        }}
        exporter={(data) => exporter(data, translate)}
        sort={{ field: "created", order: "DESC" }}
      >
        <ResponsiveList
          rowStyle={rowStyle}
          mobileHeader={
            <Fragment>
              <TextField source="customer.fullname" />
              <TextField source="reminder_category.name" />
            </Fragment>
          }
          mobileBody={
            <Fragment>
              <DateField source="created" showTime />
              <DateField source="modified" showTime />
            </Fragment>
          }
          mobileActions={
            <Fragment>
              <EditButton />
            </Fragment>
          }
        >
          <TextField source="id" />
          <TextField
            label={fieldLabel("customer_id")}
            source="customer.fullname"
            variant="subtitle2"
            sortBy="Customers.name"
          />
          <CustomerEmployeeField
            label={fieldLabel("customer_employee_id")}
            source="customer_employee.fullname"
            sortBy="CustomerEmployees.surname"
            isCustomer={isCustomer}
          />
          <TextField
            label={fieldLabel("customer_office_id")}
            source="customer_office.name"
            sortBy="CustomerOffices.name"
          />
          <ReminderCategoryField
            label={fieldLabel("reminder_category_id")}
            source="reminder_category.name"
            sortBy="ReminderCategories.name"
            isCustomer={isCustomer}
          />

          <StatusField source="status" sortBy="status" />
          <DateField source="expiry_date" />
          <ReminderField
            source="reminder_date"
            sortBy="Reminders.reminder_date"
          />

          <GroupsField
            source="groups"
            sortable={false}
            isCustomer={isCustomer}
          />
          <EditButton
            label={isCustomer ? "ra.action.view" : "ra.action.edit"}
          />
        </ResponsiveList>
      </RaList>
    </Fragment>
  );
};
export default List;
