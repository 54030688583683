import { makeStyles, useMediaQuery } from "@material-ui/core";
import { useDatagridStyles, useSelector } from "../lists/useStyles";

import { Datagrid } from "react-admin";
import Mobilegrid from ".//Mobilegrid";
import PropTypes from "prop-types";
import React from "react";

const getWidthToSubtract = (w) => {
  return w + (window.innerWidth - document.documentElement.clientWidth);
};

const useStyles = makeStyles((theme) => ({
  container: (props) => ({
    maxWidth: `calc(100vw - ${
      props.isMobile
        ? 0
        : props.sidebarOpen
        ? getWidthToSubtract(288)
        : getWidthToSubtract(104)
    }px)`,
    overflowX: "auto",
    overflowY: "hidden",
  }),
}));

const renderChildren = (component, props) =>
  React.isValidElement(component) &&
  React.Children.map(component.props.children, (field, index) =>
    React.isValidElement(field)
      ? React.cloneElement(field, {
          key: field.props.source || index,
          ...props,
        })
      : null
  );

const ResponsiveList = ({
  ids,
  data,
  basePath,
  resource,
  children,
  mobileHeader,
  mobileActions,
  mobileBody,
  breakpoint,
  hasList,
  hasEdit,
  hasShow,
  permissions,
  classes,
  syncWithLocation,
  ...props
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down(breakpoint));
  const usefulProps = {
    ids,
    data,
    basePath,
    resource,
  };
  const sidebarOpen = useSelector((state) => state.admin.ui.sidebarOpen);
  const commonClasses = useStyles({ sidebarOpen, isMobile });
  const defaultClasses = useDatagridStyles();

  if (
    isMobile &&
    (React.isValidElement(mobileBody) || React.isValidElement(mobileHeader))
  ) {
    return (
      <Mobilegrid
        header={mobileHeader}
        actions={mobileActions}
        {...usefulProps}
      >
        {renderChildren(mobileBody, usefulProps)}
      </Mobilegrid>
    );
  }
  return (
    <div className={commonClasses.container}>
      <Datagrid classes={classes || defaultClasses} {...props}>
        {React.Children.map(children, (field, index) =>
          React.isValidElement(field)
            ? React.cloneElement(field, {
                key: field.props.source || index,
                ...usefulProps,
              })
            : null
        )}
      </Datagrid>
    </div>
  );
};

ResponsiveList.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.any),
  data: PropTypes.object,
  basePath: PropTypes.string,
  resource: PropTypes.string,
  children: PropTypes.node.isRequired,
  mobileHeader: PropTypes.node,
  mobileActions: PropTypes.node,
  mobileBody: PropTypes.node,
  breakpoint: PropTypes.string,
  classes: PropTypes.object,
};

ResponsiveList.defaultProps = {
  breakpoint: "sm",
  classes: undefined,
};

export default ResponsiveList;
