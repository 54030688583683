import React, { useMemo } from "react";

import { DateInput } from "ra-ui-materialui";
import { get } from "lodash";
import { useFormState } from "react-final-form";

const PaiedInput = (props) => {
  const formState = useFormState();
  const isPaid = useMemo(() => get(formState.values, "is_paid", false), [
    formState.values,
  ]);
  if (!isPaid) {
    return null;
  }
  return <DateInput {...props} />;
};
export default PaiedInput;
