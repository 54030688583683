import { DateInput, Filter, SearchInput } from "react-admin";

import CategoryInput from "./inputs/CategoryInput";
import CustomerInput from "../components/inputs/CustomerInput";
import OrderCategoryInput from "../components/inputs/OrderCategoryInput";
import OrderInput from "../components/inputs/OrderInput";
import React from "react";
import StatusInput from "./inputs/StatusInput";
import SubCategoryInput from "./inputs/SubCategoryInput";
import UserInput from "../components/inputs/UserInput";
import { useFiltersStyles } from "../components/lists/useStyles";

const Filters = ({ isAdmin, ...props }) => {
  const classes = useFiltersStyles();

  return (
    <Filter {...props} classes={classes}>
      <SearchInput source="q" alwaysOn fullWidth />
      <OrderInput source="order_id" fullWidth />
      <DateInput source="start_at" fullWidth />
      <DateInput source="end_at" fullWidth />
      <OrderCategoryInput source="order_category_id" fullWidth />
      <StatusInput source="status" fullWidth />
      {isAdmin && <UserInput source="user_id" fullWidth />}
      {isAdmin && <CustomerInput source="customer_id" fullWidth />}
      {isAdmin && <CategoryInput isAdmin source="order_activity_category_id" />}
      {isAdmin && props.filterValues.order_activity_category_id > 0 && (
        <SubCategoryInput source="order_activity_sub_category_id" />
      )}
    </Filter>
  );
};
export default Filters;
