import * as icons from "@material-ui/icons";

import { Grid, Typography, withStyles } from "@material-ui/core";
import { Loading, useQuery } from "react-admin";
import React, { useMemo } from "react";

import CardWithIcon from "../../components/CardWithIcon";
import IndicatorField from "../../order-indicators/fields/IndicatorField";
import { get } from "lodash";

const IndicatorsField = withStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
  },
}))(({ classes, record, indicatorsToDisplay = [] }) => {
  const { data, loading } = useQuery({
    type: "get",
    resource: `order-indicators/${get(record, "id")}`,
    payload: {
      query: {},
    },
  });
  const { indicators, order } = useMemo(
    () => ({
      indicators: get(data, "data.indicators", []).filter(
        (indicator) =>
          indicatorsToDisplay.length === 0 ||
          indicatorsToDisplay.indexOf(indicator.code) !== -1
      ),
      order: get(data, "data.order", {}),
    }),
    [data, indicatorsToDisplay]
  );
  if (loading) {
    return <Loading />;
  }
  return (
    <Grid container spacing={2} className={classes.root}>
      {indicators.map((indicator) => (
        <Grid item lg={4} md={4} sm={6} xs={12} key={indicator.id}>
          <CardWithIcon
            icon={icons[indicator.icon] || icons.Info}
            title={indicator.name}
            subtitle={
              <IndicatorField
                variant="subtitle2"
                source={indicator.code}
                indicator={indicator}
                record={order}
              />
            }
          >
            <Typography
              variant="body2"
              dangerouslySetInnerHTML={{ __html: indicator.description }}
            />
          </CardWithIcon>
        </Grid>
      ))}
    </Grid>
  );
});

export default IndicatorsField;
