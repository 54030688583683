import { CloneButton as RaCloneButton } from "react-admin";
import React from "react";

const CloneButton = ({ record, ...props }) => (
  <RaCloneButton
    {...props}
    record={{
      ...record,
      is_planned: false,
    }}
  />
);
export default CloneButton;
