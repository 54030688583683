import {
  DateField,
  EditButton,
  EmailField,
  List as RaList,
  TextField,
  useTranslate,
} from "react-admin";
import React, { Fragment } from "react";
import { useListStyles, useSelector } from "../components/lists/useStyles";

import Filters from "./Filters";
import LongTextField from "../components/fields/LongTextField";
import PhoneField from "./fields/PhoneField";
import ResponsiveList from "../components/lists/ResponsiveList";
import exporter from "./exporter";
import perPage from "../utils/per-page";
import useFieldLabel from "../components/useFieldLabel";
import GroupsField from "./fields/GroupsField";

const List = (props) => {
  const translate = useTranslate();
  const sidebarOpen = useSelector((state) => state.admin.ui.sidebarOpen);
  const isCustomer = props.permissions && props.permissions(["customer"]);
  const classes = useListStyles({ sidebarOpen });
  const fieldLabel = useFieldLabel({ ...props });
  const additionalProps = isCustomer ? { bulkActionButtons: false } : {};
  return (
    <RaList
      {...props}
      {...additionalProps}
      classes={classes}
      perPage={perPage(25, 25)}
      filters={<Filters isCustomer={isCustomer} />}
      hasCreate={!isCustomer}
      exporter={(data) => exporter(data, translate)}
      sort={{ field: "created", order: "DESC" }}
    >
      <ResponsiveList
        mobileHeader={
          <Fragment>
            <LongTextField source="fullname" variant="subtitle2" />
          </Fragment>
        }
        mobileBody={
          <Fragment>
            <EmailField source="email" />
            <PhoneField source="phone" />
          </Fragment>
        }
        mobileActions={
          <Fragment>
            <EditButton />
          </Fragment>
        }
      >
        <TextField source="id" />
        {!isCustomer && (
          <TextField
            source="customer.fullname"
            label={fieldLabel("customer_id")}
            sortable={false}
          />
        )}
        <TextField source="name" variant="subtitle2" />
        <TextField source="surname" variant="subtitle2" />
        <EmailField source="email" />
        <PhoneField source="phone" />
        <GroupsField
          label={fieldLabel("groups")}
          source="groups"
          isCustomer={isCustomer}
          sortable={false}
        />
        <DateField source="modified" showTime />
        <EditButton label={isCustomer ? "ra.action.view" : "ra.action.edit"} />
      </ResponsiveList>
    </RaList>
  );
};
export default List;
