import { Filter, SearchInput } from "react-admin";

import CustomerInput from "../components/inputs/CustomerInput";
import React from "react";
import { useFiltersStyles } from "../components/lists/useStyles";
import GroupsInput from "./input/GroupsInput";
import useFieldLabel from "../components/useFieldLabel";

const Filters = ({ isCustomer, ...props }) => {
  const fieldLabel = useFieldLabel({ ...props });
  const classes = useFiltersStyles();
  return (
    <Filter {...props} classes={classes}>
      <SearchInput source="q" alwaysOn fullWidth />
      {!isCustomer && <CustomerInput source="customer_id" fullWidth />}
      <GroupsInput label={fieldLabel("group_id")} source="group_id" />
    </Filter>
  );
};
export default Filters;
