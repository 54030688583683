import React from "react";
import { SelectInput } from "react-admin";

const STATUSES = ["pending", "expiring", "expired"].map((id) => ({
  id,
  name: `resources.reminders.status.${id}`,
}));

const StatusInput = (props) => (
  <SelectInput {...props} source="status" choices={STATUSES} />
);

export default StatusInput;
