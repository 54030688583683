import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";
import ExpandMore from "@material-ui/icons/ExpandMore";
import React, { Fragment } from "react";
import { translate } from "react-admin";
import compose from "recompose/compose";

const styles = {
	listItem: {
		paddingLeft: "1rem",
		textTransform: "uppercase",
	},
	listItemText: {
		paddingLeft: 2,
		fontSize: ".5rem",
	},
	listItemTextPrimary: {
		fontWeight: "bold",
	},
	listItemIcon: {
		minWidth: "auto",
	},
	sidebarIsOpen: {
		transition: "padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
	},
	sidebarIsClosed: {
		paddingLeft: 0,
		transition: "padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
	},
};

const SubMenu = ({
	handleToggle,
	sidebarIsOpen,
	isOpen,
	name,
	icon,
	classes,
	children,
	translate,
}) => {
	return (
		<Fragment>
			<ListItem button onClick={handleToggle} className={classes.listItem}>
				{sidebarIsOpen && (
					<ListItemText
						primary={isOpen ? translate(name) : ""}
						secondary={isOpen ? "" : translate(name)}
						classes={{ primary: classes.listItemTextPrimary }}
						className={classes.listItemText}
					/>
				)}
				<ListItemIcon className={classes.listItemIcon}>
					{isOpen ? <ExpandMore /> : icon}
				</ListItemIcon>
			</ListItem>
			<Collapse in={isOpen} timeout="auto" unmountOnExit>
				<List
					component="div"
					disablePadding
					className={
						sidebarIsOpen ? classes.sidebarIsOpen : classes.sidebarIsClosed
					}
				>
					{children}
				</List>
				<Divider />
			</Collapse>
		</Fragment>
	);
};

const enhance = compose(withStyles(styles), translate);

export default enhance(SubMenu);
