import { downloadCSV } from "react-admin";
import { get } from "lodash";
import jsonExport from "jsonexport/dist";

const exporter = (data, translate) => {
  const columnName = (name) => translate(`resources.orders.fields.${name}`);
  const ordersToExport = data.map((order) => ({
    [columnName("year")]: get(order, "year"),
    [columnName("number")]: get(order, "number"),
    [columnName("customer_id")]: get(order, "customer.name"),
    [columnName("description")]: get(order, "description"),
    [columnName("owner_id")]: get(order, "owner.name"),
    [columnName("status")]: translate(
      `resources.orders.status.${get(order, "status")}`
    ),
    [columnName("order_category_id")]: get(order, "order_category.name"),
    [columnName("amount")]: get(order, "amount"),
  }));
  jsonExport(
    ordersToExport,
    {
      rowDelimiter: ";",
      headers: [
        columnName("year"),
        columnName("number"),
        columnName("customer_id"),
        columnName("description"),
        columnName("owner_id"),
        columnName("status"),
        columnName("order_category_id"),
        columnName("amount"),
      ],
    },
    (err, csv) => downloadCSV("\uFEFF" + csv, "orders")
  );
};

export default exporter;
