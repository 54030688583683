import { BooleanInput, DateInput, SimpleForm, required } from "react-admin";

import MoneyInput from "../components/inputs/MoneyInput";
import OrderCategoryInput from "../components/inputs/OrderCategoryInput";
import PaiedInput from "./inputs/PaiedInput";
import React from "react";
import ReferenceToolbar from "../components/forms/ReferenceToolbar";
import TextInput from "../components/inputs/TextInput";
import useValidatableSave from "../components/forms/useValidatableSave";

const Form = (props) => {
  const { record } = props;
  const save = useValidatableSave({
    ...props,
    redirect: `/orders/${record.order_id}/2`,
  });

  return (
    <SimpleForm
      {...props}
      save={save}
      initialValues={{ ...record, is_paid: false }}
      toolbar={
        <ReferenceToolbar
          backReference="orders"
          backReferenceTarget="order_id"
          backTab={2}
        />
      }
    >
      <OrderCategoryInput source="order_category_id" validate={required()} />
      <DateInput source="date" />
      <TextInput source="number" maxLength={50} />
      <TextInput source="notes" multiline fullWidth maxLength={4000} />
      <MoneyInput source="amount" validate={required()} />
      <DateInput source="expiry" />
      <BooleanInput source="is_paid" />
      <PaiedInput source="paied" />
    </SimpleForm>
  );
};
export default Form;
