import {
  ArrayInput,
  BooleanInput,
  SimpleFormIterator,
  required,
} from "react-admin";

import MoneyInput from "../../components/inputs/MoneyInput";
import QuantityTypeInput from "../../components/inputs/QuantityTypeInput";
import React from "react";
import TextInput from "../../components/inputs/TextInput";
import { makeStyles } from "@material-ui/core";
import useFieldLabel from "../../components/useFieldLabel";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > label > span": {
      fontSize: 24,
    },
    "& ul": {
      marginTop: theme.spacing(4),
      borderTop: 1,
      borderTopStyle: "solid",
      borderTopColor: theme.palette.secondary[500],
    },
  },
  input: {
    display: "inline-grid",
    float: "left",
    minWidth: 260,
    marginRight: theme.spacing(2),
  },
}));
const SubCategoriesInput = (props) => {
  const classes = useStyles();
  const fieldLabel = useFieldLabel({
    resource: "order-activity-sub-categories",
  });
  return (
    <ArrayInput {...props} classes={{ root: classes.root }}>
      <SimpleFormIterator>
        <TextInput
          source="name"
          label={fieldLabel("name")}
          maxLength={45}
          validate={required()}
          className={classes.input}
        />
        <QuantityTypeInput
          label={fieldLabel("quantity_type_id")}
          className={classes.input}
          source="quantity_type_id"
          validate={required()}
        />
        <MoneyInput
          label={fieldLabel("default_cost")}
          source="default_cost"
          className={classes.input}
          defaultValue={null}
        />
        <BooleanInput
          label={fieldLabel("is_user_required")}
          source="is_user_required"
          className={classes.input}
        />
        <BooleanInput
          label={fieldLabel("is_quantity_required")}
          source="is_quantity_required"
          className={classes.input}
        />
      </SimpleFormIterator>
    </ArrayInput>
  );
};
export default SubCategoriesInput;
