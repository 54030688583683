import { makeStyles } from "@material-ui/core";

const _useFormStyles = makeStyles(
  (theme) => ({
    main: {
      "& .MuiToolbar-root": {
        marginLeft: -theme.spacing(1),
      },
    },
    [theme.breakpoints.down("sm")]: {
      main: {
        width: "calc(100vw - 2em)",
        margin: "1em",
        marginBottom: "6em",
        "& .MuiFormControl-root": {
          width: "100%",
        },
        "& .RaToolbar-spacer-*": {
          display: "none",
        },
        "& .MuiCardContent-root": {
          marginBottom: theme.spacing(2),
          overflow: "auto",
        },
        "& .MuiToolbar-root": {
          marginLeft: theme.spacing(0),
        },
      },
    },
  }),
  { name: "RaMobileList" }
);

export const useFormStyles = () => _useFormStyles();
