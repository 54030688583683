import {
  AppBar,
  MenuItemLink,
  UserMenu,
  useAuthProvider,
  useGetIdentity,
  useNotify,
  useRedirect,
  useTranslate,
} from "react-admin";
import React, { forwardRef } from "react";

import Logo from "./Logo";
import LogoutIcon from "@material-ui/icons/ExitToAppOutlined";
import Notification from "./Notification";
import Typography from "@material-ui/core/Typography";
import UserIcon from "@material-ui/icons/VerifiedUserRounded";
import { grey } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import useImpersonate from "../../useImpersonate";
import { useMediaQuery } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    marginLeft: theme.spacing(1),
  },
  spacer: {
    flex: 1,
  },
  badge: {
    color: grey[100],
  },
  badgeCount: {
    color: grey[100],
    right: theme.breakpoints.down("md") ? 5 : -3,
    top: theme.breakpoints.down("md") ? 0 : 5,
  },
  [theme.breakpoints.down("md")]: {
    root: {
      "& .MuiToolbar-root": {
        paddingRight: 0,
      },
      "& .MuiIconButton-root": {
        padding: 0,
        paddingRight: theme.spacing(2),
      },
    },
  },
}));

const ConfigurationMenu = forwardRef((props, ref) => {
  const translate = useTranslate();
  const redirect = useRedirect();
  const notify = useNotify();
  const impersonate = useImpersonate();
  const authProvider = useAuthProvider();
  const handleImpersonateLogout = (e) => {
    e.preventDefault();
    e.stopPropagation();
    authProvider
      .stopImpersonate()
      .then(() => {
        redirect("/");
        setTimeout(() => document.location.reload(), 100);
      })
      .catch((error) => notify(error, "warning"));
  };
  return [
    <MenuItemLink
      key={1}
      ref={ref}
      to="/user-profile"
      primaryText={translate("ra.auth.profile")}
      leftIcon={<UserIcon />}
      onClick={props.onClick}
    />,
    impersonate && (
      <MenuItemLink
        key={2}
        ref={ref}
        to="/impersonate"
        leftIcon={<LogoutIcon />}
        primaryText={translate("ra.auth.impersonate_logout")}
        onClick={handleImpersonateLogout}
      />
    ),
  ];
});

const CustomUserMenu = (props) => (
  <UserMenu {...props}>
    <ConfigurationMenu />
  </UserMenu>
);

const CustomAppBar = (props) => {
  const classes = useStyles();
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const translate = useTranslate();
  const { loaded, identity } = useGetIdentity();

  return (
    <AppBar {...props} className={classes.root} userMenu={<CustomUserMenu />}>
      <Logo dark={true} />
      {isDesktop && (
        <Typography
          variant="h6"
          color="inherit"
          className={classes.title}
          id="react-admin-title"
        />
      )}
      <span className={classes.spacer} />
      {isDesktop && loaded && translate("app.welcome", identity)}
      <Notification />
    </AppBar>
  );
};

export default CustomAppBar;
