import React from "react";
import {
	FormDataConsumer,
	ReferenceInput,
	required,
	SelectInput,
	SimpleForm,
	TextInput,
} from "react-admin";
import RaInput from "../components/inputs/RaInput";

const Form = ({ create, ...props }) => {
	return (
		<SimpleForm {...props} redirect="list">
			{create && (
				<ReferenceInput source="resource" reference="resources" allowEmpty>
					<SelectInput helperText="resources.language-messages.helpers.resource" />
				</ReferenceInput>
			)}
			<ReferenceInput
				source="language_id"
				reference="languages"
				validate={required()}
			>
				<SelectInput />
			</ReferenceInput>
			<FormDataConsumer>
				{({ formData, ...rest }) => {
					let { resource } = formData;
					if (!resource) {
						return (
							<React.Fragment>
								<RaInput>
									<TextInput
										{...rest}
										source="code"
										validate={required()}
										fullWidth
										style={{ width: "100%" }}
									/>
								</RaInput>
								<RaInput>
									<TextInput
										{...rest}
										source="text"
										multiline
										validate={required()}
										fullWidth
										style={{ width: "100%" }}
										rows={4}
									/>
								</RaInput>
							</React.Fragment>
						);
					}
				}}
			</FormDataConsumer>
		</SimpleForm>
	);
};
export default Form;
