import { Chip, makeStyles, Divider } from "@material-ui/core";
import React, { Fragment, useMemo } from "react";

import { TextField, useGetIdentity } from "react-admin";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0.5),
  },
}));

const ReminderCategoryField = ({ source, record, isCustomer }) => {
  const { loaded, identity } = useGetIdentity();
  const groups = loaded ? identity?.groups || [] : [];
  const classes = useStyles();
  const recordGroups = record?.reminder_category?.groups;
  const finalGroups = useMemo(
    () =>
      isCustomer
        ? recordGroups?.filter((recordGroup) => {
            return (
              groups.length === 0 ||
              groups.filter((group) => {
                return group.id === recordGroup.id;
              }).length > 0
            );
          })
        : recordGroups,
    [isCustomer, recordGroups, groups]
  );

  return (
    <Fragment>
      <TextField source={source} record={record} />
      {finalGroups?.length > 0 && <Divider />}
      {finalGroups?.map((finalGroup, index) => (
        <Chip className={classes.root} key={index} label={finalGroup.name} />
      ))}
    </Fragment>
  );
};
export default ReminderCategoryField;
