import React from "react";
import HelpBlock from "./HelpBlock";
import useHelpProvider from "./useHelpProvider";

const HelpProvider = ({ ...props }) => {
  const { hasHelp, url, ...helpProviderProps } = useHelpProvider(
    "HelpProvider"
  );
  if (!hasHelp) {
    return null;
  }

  return <HelpBlock {...props} {...helpProviderProps} />;
};
export default HelpProvider;
