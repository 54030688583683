import { Alert, AlertTitle } from "@material-ui/lab";
import { NumberInput, useTranslate } from "react-admin";
import React, { Fragment, useMemo } from "react";
import { Typography, makeStyles } from "@material-ui/core";

import classNames from "classnames";
import { get } from "lodash";
import moment from "moment";
import { useFormState } from "react-final-form";

const STATUS_SEVERITY = {
  pending: "info",
  expiring: "warning",
  expired: "error",
};

const useStyles = makeStyles((theme) => ({
  input: {},
  inputEndAdornment: {
    minWidth: 100,
  },
}));
const ReminderInput = ({ ...props }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const dateFormat = translate("app.date_format.short");
  const formState = useFormState();
  const { reminderDate, status } = useMemo(() => {
    const reminder = get(formState.values, "reminder", 0);
    const expiryDate = get(formState.values, "expiry_date", null);
    const status = get(formState.values, "status", "pending");
    const reminderDate = expiryDate
      ? moment(expiryDate).subtract(reminder, "days")
      : null;
    return { reminderDate, status };
  }, [formState]);
  return (
    <Fragment>
      <NumberInput
        {...props}
        className={classNames(classes.input, props.className)}
        helperText={`resources.reminders.fields.${props.source}.helper_text`}
        InputProps={{
          endAdornment: (
            <Typography className={classes.inputEndAdornment}>
              {translate("resources.reminders.fields.days_before")}
            </Typography>
          ),
        }}
      />
      <Alert severity={STATUS_SEVERITY[status]}>
        <AlertTitle>
          {translate(`resources.reminders.fields.next_reminder_date`)}
        </AlertTitle>
        <Typography variant="body2">
          {reminderDate && reminderDate.format(dateFormat)}
        </Typography>
      </Alert>
    </Fragment>
  );
};
export default ReminderInput;
