import { Avatar, Chip } from "@material-ui/core";

import React from "react";
import { get } from "lodash";
import { useTranslate } from "react-admin";

const StatusField = ({ record }) => {
  const translate = useTranslate();
  const status = get(record, "status", "pending");
  return (
    <Chip
      label={translate(`resources.reminders.status.${status}`)}
      color={status !== "pending" ? "primary" : "default"}
      avatar={status !== "pending" ? <Avatar>!</Avatar> : null}
    />
  );
};
export default StatusField;
