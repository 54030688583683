import {
  TextInput as RaTextInput,
  maxLength as maxLengthValidator,
  useTranslate,
} from "react-admin";

import React from "react";
import { get } from "lodash";
import { useFormState } from "react-final-form";

const TextInput = ({ maxLength, helperText, validate, ...props }) => {
  const translate = useTranslate();
  const { values } = useFormState();
  const formData = get(values, props.source);
  const usedCharsInfo = translate("app.input.max_length_info", {
    count: get(formData, "length", 0),
    max: maxLength,
  });
  const validationFn = maxLengthValidator(maxLength);
  if (!validate) {
    validate = [validationFn];
  } else if (Array.isArray(validate)) {
    if (validate.indexOf(validationFn) === -1) {
      validate.push(validationFn);
    }
  }

  if (maxLength) {
    helperText =
      helperText && helperText.length > 0
        ? `${usedCharsInfo} - ${helperText}`
        : usedCharsInfo;
  }

  return <RaTextInput {...props} helperText={helperText} validate={validate} />;
};
export default TextInput;
