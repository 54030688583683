import { createMuiTheme } from "@material-ui/core/styles";
import red from "@material-ui/core/colors/red";

export default createMuiTheme({
  palette: {
    background: {
      default: "#EDECEC",
    },
    primary: red,
    secondary: {
      main: "#003471",
    },
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.3,
  },
  overrides: {
    MuiButton: {
      containedPrimary: {
        color: "#FFF",
      },
    },
  },
});
