import Card from "@material-ui/core/Card";
import CardIcon from "./CardIcon";
import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslate } from "react-admin";

const useStyles = makeStyles((theme) => ({
  main: {
    flex: "1",
    marginTop: 20,
    marginBottom: 16,
    minHeight: 100,
    [theme.breakpoints.up("sm")]: {
      marginRight: "1rem",
    },
  },
  card: {
    overflow: "inherit",
    textAlign: "right",
    padding: 16,
    minHeight: 100,
  },
}));

const Indicator = ({ label, value, color, icon }) => {
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <div className={classes.main}>
      <CardIcon Icon={icon} color={color} />
      <Card className={classes.card}>
        <Typography className={classes.title} color="textSecondary">
          {translate(label)}
        </Typography>
        <Typography variant="subtitle1" component="h2">
          {value}
        </Typography>
      </Card>
    </div>
  );
};
export default Indicator;
