import {
  Edit as RaEdit,
  Toolbar as RaToolbar,
  SaveButton,
  SimpleForm,
  TextInput,
  required,
  useNotify,
  usePermissions,
} from "react-admin";
import React, { Fragment } from "react";

import HelpProvider from "../components/HelpProvider";
import PasswordInput from "../users/inputs/PasswordInput";
import Title from "../components/Title";
import { useFormStyles } from "../components/forms/useStyles";
import useValidatableSave from "../components/forms/useValidatableSave";

const Toolbar = (props) => (
  <RaToolbar {...props}>
    <SaveButton />
  </RaToolbar>
);

const Edit = ({ staticContext, ...props }) => {
  const { permissions } = usePermissions();
  const classes = useFormStyles();
  const notify = useNotify();
  const save = useValidatableSave({
    resource: "users/profile",
    onSuccess: () => notify("resources.users.messages.profile_saved"),
  });
  return (
    <Fragment>
      <HelpProvider />
      <RaEdit
        {...props}
        id="profile"
        resource="users"
        basePath="profile"
        redirect=""
        title={<Title source="email" />}
        undoable={false}
        classes={classes}
      >
        <SimpleForm toolbar={<Toolbar />} save={save}>
          <TextInput source="email" validate={required()} />
          <PasswordInput permissions={permissions} />
          <TextInput source="profile.name" validate={required()} />
          <TextInput source="profile.surname" validate={required()} />
        </SimpleForm>
      </RaEdit>
    </Fragment>
  );
};

export default Edit;
