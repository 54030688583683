import React, { Fragment, useMemo } from "react";
import { EditButton, List as RaList, TextField } from "react-admin";
import HelpProvider from "../components/HelpProvider";
import ResponsiveList from "../components/lists/ResponsiveList";
import perPage from "../utils/per-page";
import { useListStyles, useSelector } from "../components/lists/useStyles";
import MediaField from "./fields/MediaField";
import useFieldLabel from "../components/useFieldLabel";
import Filters from "./Filters";
import { get } from "lodash";
import LongTextField from "../components/fields/LongTextField";
import CustomerOfficesField from "./fields/CustomerOfficesField";

const ResponsiveListWrapped = ({ isCustomer, ...props }) => {
  const fieldLabel = useFieldLabel({ ...props });
  const showCustomer = useMemo(() => {
    if (isCustomer) {
      return false;
    }
    const customerId = get(props.filterValues, "customer_id", 0);
    if (customerId === 0) {
      return true;
    } else {
      return false;
    }
  }, [isCustomer, props.filterValues]);

  return (
    <ResponsiveList
      {...props}
      mobileHeader={
        <Fragment>
          <TextField source="customer.fullname" />
        </Fragment>
      }
      mobileBody={
        <Fragment>
          <TextField source="document_category.name" />
          <TextField source="name" />
          <MediaField source="media" />
        </Fragment>
      }
      mobileActions={
        <Fragment>
          <EditButton />
        </Fragment>
      }
    >
      <TextField source="id" />
      {showCustomer && (
        <LongTextField
          label={fieldLabel("customer_id")}
          source="customer.fullname"
          variant="subtitle2"
          sortBy="Customers.name"
          maxWidth={200}
        />
      )}
      <CustomerOfficesField source="customer_office_id" variant="subtitle2" />
      <TextField source="name" variant="subtitle2" />
      <TextField
        label={fieldLabel("document_category_id")}
        source="document_category.name"
        variant="subtitle2"
        sortBy="DocumentCategories.name"
      />
      <MediaField source="media" sortable={false} />
      <EditButton label={isCustomer ? "ra.action.view" : "ra.action.edit"} />
    </ResponsiveList>
  );
};

const List = (props) => {
  const sidebarOpen = useSelector((state) => state.admin.ui.sidebarOpen);
  const classes = useListStyles({ sidebarOpen });
  const isCustomer = props.permissions && props.permissions(["customer"]);
  const additionalProps = isCustomer ? { bulkActionButtons: false } : {};
  return (
    <Fragment>
      <HelpProvider />
      <RaList
        {...props}
        {...additionalProps}
        classes={classes}
        perPage={perPage(25, 25)}
        filters={<Filters isCustomer={isCustomer} />}
        hasCreate={!isCustomer}
        exporter={false}
        sort={{ field: "created", order: "DESC" }}
      >
        <ResponsiveListWrapped isCustomer={isCustomer} />
      </RaList>
    </Fragment>
  );
};
export default List;
