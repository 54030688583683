import React from "react";
import { TextField } from "react-admin";
import { get } from "lodash";

const IdField = ({ source, record, ...props }) => {
  const id = get(record, source, null);
  if (id !== null && typeof id === "string" && id.startsWith("_")) {
    return null;
  }
  return <TextField {...props} record={record} source={source} />;
};
export default IdField;
