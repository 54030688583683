import {
  BooleanField,
  DateField,
  NumberField,
  List as RaList,
  TextField,
  useTranslate,
} from "react-admin";
import React, { Fragment } from "react";
import { useListStyles, useSelector } from "../components/lists/useStyles";

import EditButton from "./buttons/EditButton";
import Filters from "./Filters";
import IdField from "./fields/IdField";
import LongTextField from "../components/fields/LongTextField";
import MoneyField from "../components/fields/MoneyField";
import OrderField from "./fields/OrderField";
import ResponsiveList from "../components/lists/ResponsiveList";
import exporter from "./exporter";
import perPage from "../utils/per-page";
import useFieldLabel from "../components/useFieldLabel";

const List = (props) => {
  const { permissions } = props;
  const fieldLabel = useFieldLabel({ resource: "order-activities" });
  const isAdmin = permissions && permissions(["admin", "developer"]);
  const sidebarOpen = useSelector((state) => state.admin.ui.sidebarOpen);
  const classes = useListStyles({ sidebarOpen });
  const translate = useTranslate();
  return (
    <RaList
      {...props}
      filters={<Filters isAdmin={isAdmin} />}
      classes={classes}
      exporter={(data) => exporter(data, translate)}
      perPage={perPage(25, 25)}
    >
      <ResponsiveList
        mobileHeader={
          <Fragment>
            <OrderField
              linkable={isAdmin}
              label={fieldLabel("order_id")}
              source="order"
            />
          </Fragment>
        }
        mobileBody={
          <Fragment>
            <LongTextField
              label={fieldLabel("order_activity_category_id")}
              source="order_activity_category.name"
            />
            <LongTextField
              label={fieldLabel("order_activity_sub_category_id")}
              source="order_activity_sub_category.name"
            />
          </Fragment>
        }
        mobileActions={
          <Fragment>
            <EditButton />
          </Fragment>
        }
      >
        <IdField source="id" />
        {isAdmin && <BooleanField source="is_planned" />}
        <OrderField
          linkable={isAdmin}
          label={fieldLabel("order_id")}
          source="order"
          sortBy="Orders.id"
        />
        <LongTextField
          label={fieldLabel("customer")}
          source="order.customer.fullname"
          sortBy="Customers.name"
        />
        <LongTextField
          label={fieldLabel("order_description")}
          source="order.description"
          sortBy="Orders.description"
        />
        <LongTextField
          label={fieldLabel("order_activity_category_id")}
          source="order_activity_category.name"
          sortBy="OrderActivityCategories.name"
        />
        <LongTextField
          label={fieldLabel("order_activity_sub_category_id")}
          source="order_activity_sub_category.name"
          sortBy="OrderActivitySubCategories.name"
        />

        {isAdmin && <MoneyField source="total_cost" sortBy="total_cost" />}
        <NumberField source="total_days" sortBy="total_days" />
        <DateField source="date" />
        {isAdmin && (
          <TextField
            label={fieldLabel("user_id")}
            source="user.nice_name"
            sortBy="Users.username"
            variant="subtitle2"
          />
        )}
        <EditButton />
      </ResponsiveList>
    </RaList>
  );
};
export default List;
