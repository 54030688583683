import Create from "./Create";
import Edit from "./Edit";
import { IndicatorIcon } from "../icons";
import List from "./List";

export default {
  options: {
    group: "admin",
    roles: ["developer"],
  },
  icon: IndicatorIcon,
  list: List,
  edit: Edit,
  create: Create,
};
