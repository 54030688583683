import React from "react";
import { SelectInput } from "react-admin";

const STATUSES = [
  {
    id: "pending",
    name: "resources.users.status.pending",
  },
  {
    id: "active",
    name: "resources.users.status.active",
  },
];

const StatusInput = (props) => (
  <SelectInput {...props} source="status" choices={STATUSES} fullWidth />
);
export default StatusInput;
