import { EditButton as RaEditButton } from "react-admin";
import React from "react";
import { get } from "lodash";

const EditButton = (props) => {
  const id = get(props, "record.id", 0);
  if (id === 0) {
    return null;
  }
  return <RaEditButton {...props} />;
};
export default EditButton;
