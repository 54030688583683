import * as React from "react";

import { CircularProgress } from "@material-ui/core";
import useNetwork from "../../useNetwork";

const Logo = ({ dark = false }) => {
  const { loading, network } = useNetwork();
  if (loading) {
    return <CircularProgress />;
  }
  return (
    <React.Fragment>
      <img
        src={dark ? network.logo_white : network.logo_dark}
        alt={network.name}
        height={30}
      />
    </React.Fragment>
  );
};

export default Logo;
